


























export default
	name: "Selector"

	# Use existing change event for v-model
	model: event: 'change'

	props:
		items: Array # Array of `title` and `value` objects
		value: Object|String|Number # For passing through v-model
		label: String # The initial state of the menu, like when empty
		menu: Boolean # Act like a menu, don't change value
		align: # Affects the button and menu alignment
			type: String
			default: 'left'
		color:
			type: String
			default: 'brown'
		size:
			type: String
			default: 'compact' # Also: 'large
		type:
			type: String
			default: 'filled'
			validator: (val) -> val in ['filled', 'standard']

	# Capture this element to use as the attach point
	data: -> attach: null
	mounted: -> @attach = @$el

	computed:

		# Selector classes
		classes: -> [
			"color-#{@color}"
			"size-#{@size}"
			"align-#{@align}"
			"type-#{@type}"
		]

		# Menu classes
		menuClasses: -> ['selector-menu', ...@classes].join ' '

	methods:

		# Handle changes
		onChange: (e) ->
			@$emit 'change', e

			# If a menu, reset the state again
			@$refs.select.reset() if @menu

