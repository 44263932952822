import { render, staticRenderFns } from "./product-question.vue?vue&type=template&id=1323112f&scoped=true&lang=pug&"
import script from "./product-question.vue?vue&type=script&lang=coffee&"
export * from "./product-question.vue?vue&type=script&lang=coffee&"
import style0 from "./contact-form.styl?vue&type=style&index=0&id=1323112f&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1323112f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VFileInput,VForm,VTextField,VTextarea})
