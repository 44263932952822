# Deps
import LinkListCard from './link-list-card'

# Make english labels from keys
categoryGroupTitles =
	activeIngredient: 'Active Ingredient'
	availability: 'Availability'
	effect: 'Effect'
	productType: 'Product Type'
	occasion: 'Occasion'

# Make render function
export default
	name: 'CategoryGroupCard'
	functional: true

	props:

		# Layout
		aspect: Number # Applied to the image
		index: Number
		columns:
			type: Number
			default: 2
		scaleIn:
			type: Boolean
			default: true

		# Card content
		image: Object | Array
		categoryGroup: String
		categories: Array

	# Render a LinkListCard with mutated props
	render: (create, { props, data }) ->
		create LinkListCard, {
			...data
			props: {
				...props

				# Make the title from the selected group
				menuTitle: categoryGroupTitles[props.categoryGroup]

				# Make the list of category links
				links: props.categories.map ({ title, slug }) ->
					label: title
					href: "/products?#{props.categoryGroup}=#{slug}"
			}
		}
