


















import AffixingHeader from '../affixing-header'
import PrimaryNav from './primary-nav'
export default

	components: {
		AffixingHeader
		PrimaryNav
	}

	data: -> 
		return {
			buyNowUrl: '',
			isVisibleBuyNow: false
		}

	mounted: -> @cleanupOnResize = @$onResize =>
		width = parseFloat getComputedStyle(@$el).paddingLeft
		@$store.commit 'layout/setHeaderGutter', width

		# Fetch age-gate:passed
		ageGate = localStorage.getItem('age-gate:passed')
		# Fetch the stored state and update the buyNowUrl accordingly
		storedState = localStorage.getItem('selectedState')?.trim()
		stateToUrlMap = {
			'Massachusetts': 'https://ma.kivaconfections.com/',
			'New York': 'https://ny.kivaconfections.com',
			# Add more states and their URLs as needed
		}
		showState = !!stateToUrlMap[storedState]
		if ageGate && storedState && showState
			@buyNowUrl = stateToUrlMap[storedState]
			@isVisibleBuyNow = true
		else
			@isVisibleBuyNow = false

	props: ['selectedState']

	watch:
		selectedState:  (newState) ->
			# Logic to update buyNowUrl and isVisibleBuyNow based on newState
			# This is similar to what you have in mounted, but now it reacts to prop changes
			this.updateBuyNowVisibility(newState)

	methods:
		updateBuyNowVisibility: (newState) ->
			# Assuming newState is the selected state
			ageGate = localStorage.getItem('age-gate:passed')
			stateToUrlMap = {
				'Massachusetts': 'https://ma.kivaconfections.com/',
				'New York': 'https://ny.kivaconfections.com',
				# other states...
			}
			showState = !!stateToUrlMap[newState]
			if ageGate && newState && showState
				@buyNowUrl = stateToUrlMap[newState]
				@isVisibleBuyNow = true
			else
				@isVisibleBuyNow = false


	destroyed: -> @cleanupOnResize?()

