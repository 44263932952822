














import CraftBtn from '~/components/global/btn/craft-btn'
export default

	components: { CraftBtn }

	props: buttons: 
		type: Array
		default: -> []

