import { render, staticRenderFns } from "./two-column-table.vue?vue&type=template&id=1973ee69&scoped=true&lang=pug&"
import script from "./two-column-table.vue?vue&type=script&lang=coffee&"
export * from "./two-column-table.vue?vue&type=script&lang=coffee&"
import style0 from "./two-column-table.vue?vue&type=style&index=0&id=1973ee69&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1973ee69",
  null
  
)

export default component.exports