





































import Checkbox from './checkbox'
import FilterTitle from './filters-title'
export default

	components: {
		Checkbox
		FilterTitle
	}

	props:
		title: String
		categories: Array
		choices: Object
		total: Number
		noTitle: Boolean

	data: -> openState: []

	computed:

		# Check if anything have been selected
		hasSelections: ->
			for key, selections of @choices
				return true if selections.length
			return false

	methods:

		# Open panes that have selected choices
		openSelected: ->
			@openState = Object.values(@choices)
			.reduce (state, selections, index) ->
				if selections.length then state.push index
				return state
			, []

