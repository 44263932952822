



















export default

	data: -> year: new Date().getFullYear()

