


























export default

	props: block: Object

	computed:

		# Make HTML for the pane
		panes: ->
			(@block.panes || [] ).map (pane) => {
				...pane
				body: """
					#{pane.accordionPaneBody || ''}
					#{@makeDictionary(pane.accordionPaneDictionary)}
				"""
			}

	methods:

		# Make a table from dictionary data
		makeDictionary: (dictionary) ->
			rows = dictionary?.filter (val) -> !!val.term # Filter empties
			return '' unless rows?.length
			"""
			<table>
					#{rows.map(({term, value }) ->
						"""
						<tr>
							<td>#{term}</td>
							<td>#{value}</td>
						</tr>
						"""
					).join('')}
			</table>
			"""

