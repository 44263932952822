













export default

	props:

		# The main button type
		type:
			type: String
			default: 'outline'
			validator: (val) -> val in ['text', 'outline', 'filled']

		# Button color
		color:
			type: String|Boolean
			default: 'brown'
			validator: (val) -> !val || val in ['brown', 'black', 'white']

		# State
		disabled: Boolean
		loading: Boolean

		# Styling tweaks
		square: Boolean
		noUnderline: Boolean
		noReveal: Boolean

	computed:

		classes: -> [
			"type-#{@type}" if @type
			"color-#{@color}" if @color
			{ @disabled, @loading, @square }
			'no-underline': @noUnderline
		].filter (val) -> !!val

	methods:

		# Prevent clicks if disabled
		onClick: -> @$emit 'click' unless @disabled

