# Open the age gate modal if the user hasn't agreed to it before
import { mountOnBody } from './helpers'
import AgeGate, { LS_KEY } from '~/components/global/modals/age-gate'
import { localStorage } from './local-storage'

export default ({}, inject) ->

	# Create a global promise to store whether the age gate was passed.  This
	# could have been something did via vuex but I would need to watch for it
	# to change from the newsletter modal plugin and that felt more cumbersome
	# to me.
	inject 'ageGatePassed', new Promise (resolve) ->

		# Instantly resolve if they've already passed. Otherwise open the age gate
		# and wait for it to be passed
		if localStorage.getItem(LS_KEY)
		then resolve()
		else (await mountOnBody(AgeGate)).$on 'passed', -> resolve()

