


























export default

	props: 
		article: Object

	computed: 

		# Make url to the article
		href: -> "/#{@article.uri}"

		# Get the category
		category: -> @article.blogCategory?[0]

