

















export default

	model: prop: 'model'

	props:
		label: String
		value: String
		model: Array
		static:
			type: Boolean
			default: false

	computed:

		# Is this box checked
		checked: -> @value in @model

	methods:

		# Toggle the value off and on the model array
		toggle: ({target}) ->

			if @static and not @checkboxClicked target then return
			if @checked
				index = @model.indexOf @value
				@model.splice index, 1
			else @model.push @value

		checkboxClicked: (target) ->
			if target.tagName is "BUTTON" then return true
			else if target.parentNode.tagName is "BUTTON" then return true
			else false

