###
Validate manually since I was running into validation issues when SSGed
on Netlify 🤷‍♂️
###
export default

	data: ->

		# No-ops
		form: {}
		validation: {}

	# Test for validity of whole form
	computed: valid: ->
		for key, val of @form
			if @validation[key]
				for rule in @validation[key]
					return false if rule(val) != true
		return true
