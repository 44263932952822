















import Vue from 'vue'
import TwoColumnTable from '~/components/global/two-column-table'
import getFlavorTranslations from '~/queries/blocks/flavor-translations.gql'

export default

	components: {
		TwoColumnTable
	}
	props:
		block:
			type: Object
			default: -> {} # Not defined when used from pages

	asyncData: ({ app: { $craft }}, block) ->

		{ englishFlavors, spanishFlavors } = await $craft.execute
			query: getFlavorTranslations

		Vue.set block, 'englishFlavors', englishFlavors
		Vue.set block, 'spanishFlavors', spanishFlavors

	computed:
		translations: -> @mergeTranslations(@block.englishFlavors, @block.spanishFlavors)?.map (flavor) ->
			id: flavor.id

			# Titles
			enTitle: "#{flavor.en?.parentTitle} - #{flavor.en?.title}" || ''
			esTitle: "#{flavor.es?.parentTitle} - #{flavor.es?.title}" || ''

			# Ingredients. English | Spanish
			ingredients: (flavor.en?.ingredients || flavor.es?.ingredients) && [flavor.en?.ingredients, flavor.es?.ingredients]

			# Description. English | Spanish
			description: (flavor.en?.description || flavor.es?.description) && [flavor.en?.description, flavor.es?.description]

			# Terpenes. English | Spanish
			terpenes: (flavor.en?.terpenes || flavor.es?.terpenes) &&  [flavor.en?.terpenes, flavor.es?.terpenes]

			# Additional Information. English | Spanish
			additionalInformation:
				flavor.es?.additionalInformation?.map (info, i) ->
					[
						flavor.en?.additionalInformation && flavor.en?.additionalInformation[i].listItem || ''
						flavor.es?.additionalInformation && flavor.es?.additionalInformation[i].listItem || ''
					]

			# Stats. English | Spanish
			stats:
				flavor.es?.stats?.map (info, i) ->
					[
						flavor.en?.stats && flavor.en?.stats[i] && "#{flavor.en?.stats[i].label}: #{flavor.en?.stats[i].value}"  || ''
						flavor.es?.stats && flavor.es?.stats[i] && "#{flavor.es?.stats[i].label}: #{flavor.es?.stats[i].value}"  || ''
					]

			# Stats. English | Spanish
			nutrition:
				flavor.es?.nutrition?.map (info, i) ->
					[
						flavor.en?.nutrition && flavor.en?.nutrition[i] && "#{flavor.en?.nutrition[i].term}: #{flavor.en?.nutrition[i].value}"  || ''
						flavor.es?.nutrition && flavor.es?.nutrition[i] && "#{flavor.es?.nutrition[i].term}: #{flavor.es?.nutrition[i].value}"  || ''
					]

	methods:

		getTranslationRows: (flavor) ->
			[
				['English', 'Spanish'],
				flavor.description || []
				flavor.ingredients || []
				flavor.terpenes || []
				...flavor.additionalInformation || []
				...flavor.stats || []
				...flavor.nutrition || []
			]

		hasIllinois: (regional) -> regional.regions.some (region) -> region.slug == 'il'

		# Merge translations
		mergeTranslations: (englishFlavors = [], spanishFlavors= []) ->
			flavors = []

			[{lang: 'en',  flavors: englishFlavors }, {lang: 'es',  flavors: spanishFlavors }].forEach (lang) =>
				for flavor in lang.flavors

					# If it has Illinois region
					if !(flavor.regionalContent.some @hasIllinois ) then continue

					illinoisRegion = flavor.regionalContent.find @hasIllinois

					flav = flavors.find (f) -> f.id == flavor.id

					if flav
						flav[lang.lang] = {...illinoisRegion, title: flavor.title, parentTitle: flavor.parent.title}
					else
						flavors.push
							id: flavor.id,
							[lang.lang]: {...illinoisRegion, title: flavor.title, parentTitle: flavor.parent.title}

			return flavors

