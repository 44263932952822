






































LS_KEY = 'closed-promo-bars'
export default

	data: ->
		closedList: []
		bar: null

	# On mount, measure the bar height and then animate it in
	mounted: ->
		@getClosedBanners()
		@initialDelay = @$wait 1000, => @initialDelayPassed = true

	destroyed: -> clearTimeout @initialDelay

	computed:

		# Vuex accessors
		bars: -> @$store.state['promo-bars'].bars
		height: -> @$store.state['promo-bars'].height

		# Set the theme
		barStyle: -> background: @bar?.promoBarTheme
		barClass: -> dark: @$isDark @bar?.promoBarTheme

		# Get the list of bars the user hasn't closed for the current page
		unclosed: -> @bars.filter ({ id }) => id not in @closedList

		# Get the bar to show, which is the first of the unclosed ones
		currentBanner: -> @unclosed?[0]

		# Show a bar if the user hasn't closed it
		shown: -> @bar and @unclosed.length > 0

		# Used to watch for when a bar is shown
		shownBanner: -> @shown and @bar

	watch:

		# Don't unset the last bar, so when it closes, you can still see a bar
		currentBanner: (bar) -> @bar = bar if bar

		# Measure the height of the current bar
		bar: -> @$nextTick @measureHeight

		# Remeasure the height when shown toggled
		shown: -> @$nextTick @measureHeight

		# Remeasure on resize
		'$store.state.layout.viewport.width': -> @measureHeight()

		# Update the closed list in LocalStorage
		closedList: (list) -> @$localStorage.setItem LS_KEY, JSON.stringify list

	methods:

		# Measure the height of the bar
		measureHeight: ->
			height = @$refs.bar?.getBoundingClientRect().height
			@$store.commit 'promo-bars/setHeight', height


		# Get the list of closed bars
		getClosedBanners: ->
			stored = @$localStorage.getItem LS_KEY
			@closedList = if stored then JSON.parse stored else []

		# Mark a bar as closed
		closeBanner: -> @closedList.push @bar.id

