




import lotCodeInfoJPG from './lot-code-info.jpg'

export default
	data: ->
		lotCodeInfo: lotCodeInfoJPG
