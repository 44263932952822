# Open the newsletter modal after some amount of time
import { mountOnBody, wait } from './helpers'
import Newsletter from '~/components/global/modals/newsletter'
import { LS_KEY } from '~/components/global/newsletter-signup'
import { localStorage } from './local-storage'

export default ({ app, store }) ->

	# Disable locally
	return if process.env.DISABLE_NEWSLETTER_MODAL

	# If they already signed up, abort
	return if localStorage.getItem LS_KEY

	# Wait for the age gate to be passed until showing the newsletter
	app.$ageGatePassed.then ->
		wait store.state.globals.newsletterModalDelay * 1000, ->
			mountOnBody Newsletter

	# Don't wait for the age gate promise to resolve
	return null
