export default

	computed:
		# Should fields be readonly
		readonly: -> @submitting or @submitted

		formPayload: ->
			formData = new FormData()
			for key, value of @form
				formData.append(key, value)
			formData

	methods:
		onUpdate: ({field, value}) ->
			@form[field] = value

		submit: ->
			return if @submitting or @submitted
			return unless @$refs.form.validate()
			@submitting = true
			try await @postToEndpoint()
			catch error
				alert 'An error was encountered while processing your submission.'
				@$sentry?.captureException error
				return @submitting = false
			@submitting = false
			@submitted = true

		# Do the actual post
		postToEndpoint: ->@$axios
			method: 'POST'
			url: @block.endpoint
			data: @formPayload
			headers: "Content-Type": "multipart/form-data"
