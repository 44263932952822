import throttle from 'lodash/throttle'
export default ({ store }, inject) ->

	# Store the throttled window size
	storeWindowDimensions = -> store.commit 'layout/setViewportDimensions', 
		width: window.innerWidth
		height: window.innerHeight

	# DRY up setting window resize listeners and return a cleanup function
	onResize = (callback, throttleMs = 100) ->
		throttledCallback = throttle callback, throttleMs
		window.addEventListener 'resize', throttledCallback
		callback()
		return () -> window.removeEventListener 'resize', throttledCallback
	inject 'onResize', onResize

	# Add initial listeners
	onResize storeWindowDimensions
	

