












export default

	props: body: String

	computed:

		# Massage the body copy
		bodyHtml: -> @makeIframesResponsive @body

	methods: 

		# Add shim divs to figcaption's containing iframes with dimenions
		# https://regex101.com/r/xWqfyt/2
		makeIframesResponsive: (text) ->
			return unless text

			# Find figure's with iframe inside
			return text.replace /<figure>\s*<iframe([^>]+)>/gi, (match, attrs) ->

				# Get the width and height from the iframe's style
				if (widthRule = attrs.match /width:(\d+px)/) and 
				(heightRule = attrs.match /height:(\d+px)/)

					# Make the padding value
					width = parseInt widthRule[1]
					height = parseInt heightRule[1]
					padding = height / width * 100

					# Insert the shim into the match
					shim = "<div class='shim' style='padding-top: #{padding}%'></div>"
					match = match.replace '<figure>', "<figure>#{shim}"
				
				# Return the match that were found, possibly with the shim now
				return match

