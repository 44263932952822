























































import netlifyForm from '~/mixins/netlify-form'
export LS_KEY = 'newsletter:subscribed'
export default

	mixins: [ netlifyForm ]

	# Respond to dark block
	inject: blockIsDark: default: false

	props:
		includeZip: Boolean # Show optional zip code form

	data: ->
		form:
			'form-name': 'Newsletter'
			email: ''
			zip: @$store.state.buy.zip unless @includeZip
		validation:
			email: [ @$validators.required, @$validators.email ]
			zip: [ @$validators.zip ] if @includeZip

	computed:

		# Vuex accessor
		globals: -> @$store.state.globals

		# Show the success message on success unless a success event was added
		messages: ->
			return if @$listeners.success
			@globals.newsletterThanksMessageFooter if @submitted

		# Include the zip automatically
		formData: -> { ...@form }

	watch:

		# On submit remember that they submitted and fire event
		submitted: (bool) ->
			@$localStorage.setItem LS_KEY, true
			if @includeZip then @$store.commit 'buy/setZip',
				zip: @form.zip
				detected: false
			@$emit 'success' if bool

