


import Marquee from '~/components/blocks/marquee'
import { smallDate } from '~/plugins/helpers'
export default
	name: 'ArticleMarquee'
	functional: true

	props: 
		article: Object # Expects an article with listing type data
		featured: Boolean

	render: (create, { props: { article, featured }, data }) -> 
		url = "/#{article.uri}"

		# Make the copy block
		copy = "<h1>#{article.title}</h1>"
		copy += "<p class='date'>#{smallDate(article.date)}</p>" if article.date
		copy += "<p>#{article.abstract}</p>" if featured and article.abstract

		# Make the breadcrumbs
		breadcrumbs = switch article.type
			when 'blog' then [ label: 'Blog', href: '/blog' ]
			when 'news' then [ label: 'News', href: '/news' ]
		breadcrumbs.push(label: 'Featured', href: url) if featured

		# Instantiate a Visual instance
		create Marquee,

			# Map article data into the block
			props: block:
				id: article.id
				layout: 'narrow'
				primaryImage: article.articleMarqueeImage
				tallTexture: article.tallTexture
				copy: copy
				breadcrumbs: breadcrumbs
				links: [ label: 'Read more', href: url ] if featured

			# Add CSS classes
			class: [
				'article-marquee'
				'featured' if featured
				data.staticClass
			]

