





































































import DownHr from '~/components/global/dividers/down-hr'
import shouldRender from '../../mixins/should-render'
export default

	mixins: [ shouldRender ]

	components: {
		DownHr
	}

	props: block: Object

	computed:

		# Make CSS classes
		classes: -> [
			"layout-#{@block.layout}"
			'has-seperator': @block.addSeparator
		]

		# Make the sizes of the primary image, which are layout dependendent
		primarySizes: -> switch @block.layout
			when 'overlap' then '(max-width: 767px) 100vw, 70vw'
			when 'wide' then '(max-width: 767px) 100vw, 60vw'
			when 'narrow' then '(max-width: 767px) 100vw, 45vw'

		# Defaults
		breadcrumbs: ->
			(@block.breadcrumbs || [])
			.map (breadcrumb) ->
				label: breadcrumb.label || breadcrumb.title
				href: breadcrumb.href || ("/#{uri}" if uri = breadcrumb.uri)
			.filter ({ label }) -> !!label

		# Whether to show the bottom textures
		addBottomTextures: -> @block.addSeparator or @block.smallTexture?.length

		# Conditions to be used for shouldRender mixin
		conditions: -> @block.displayConditions || []




