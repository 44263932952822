











































































































































import formMixin from './form-mixin'
import Selector from '~/components/global/selector'
import LotCodeInfo from './lot-code/lot-code-info.vue'

export default

	name: "ProductComplaint"

	mixins: [ formMixin ]

	components:{
		Selector
		LotCodeInfo
	}

	inject: ['blockIsDark']

	props:
		block: Object

	data: ->
		valid: false
		validation:
			name: [ @$validators.required]
			email: [ @$validators.required, @$validators.email ]
			message: [ @$validators.required ]
			dispensary: [ @$validators.required ]
			address1: [ @$validators.required ]
			address2: [ ]
			city: [ @$validators.required ]
			state: [ @$validators.required, @$validators.state ]
			zip: [ @$validators.required, @$validators.zip ]
			productImage: [ @$validators.fileRequired ]
			batchNumber: [ @$validators.fileRequired ]
		form:
			'form-name': 'Product Complaint'
			name: ''
			email: ''
			message: ''
			address1: ''
			address2: ''
			city: ''
			state: ''
			zip: ''
			productImage: null
			batchNumber: null
		submitting: false
		submitted: false
		successMessage: "Thanks for your message. We're so sorry you had an issue with one of our products. We'll get back to you as soon as we can."

