





































import TweenNum from 'vue-tween-number'
import debounce from 'lodash/debounce'
export default

	components: {
		TweenNum
	}

	props:
		group: Object
		forceOpen: Boolean

	data: ->
		openChoice: false
		height: null

	computed:

		# Only show up to 3 results until open
		results: ->
			if @open then @group.results
			else @group.results.slice(0, 3)

		# Classes that affect whether we show the expand button
		expandClasses: ->
			'less-than-3': @group.results.length < 3
			'less-than-4': @group.results.length < 4

		# Should the list of results be expanded
		open: -> @forceOpen or @openChoice

	watch:

		# Animate the height of the results container on open / close. There is a
		# little bit of slight of hand here.  When it closes, the cards disappear
		# immediately, which is good, because it lets me measure the new height on
		# the next tick and tween to it.  And the tween is so far, you don't really
		# see the cards disapear, you just see the tween on the height. A more
		# exacting solution would require measuring the height of the first row and
		# tweening to it specifically on close, while not immediately removing the
		# cads that will be dropped.
		open: ->

			# Set the height when transitions starts
			@height = @gridHeight()
			@$defer => @height = @gridHeight()

			# Reset state when done
			clearTimeout @openWaitId
			@openWaitId = @$wait 300, =>
				@height = null

	methods:

		# Get the grid height
		gridHeight: -> @$refs.grid.clientHeight + 'px'

		# Set the grid height after result's leave, using debounce to only trigger
		# once per leave
		onResultsLeave: debounce -> @height = @gridHeight()

