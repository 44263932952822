# Card options
import CategoryGroupCard from './category-group-card'
import LinkListCard from './link-list-card'
import ListingCard from './listing-card'

# Export an adapter functional component for passing card instances in from
# Craft and unpacking the props.
export default
	name: 'CraftCard'
	functional: true
	props:
		card: Object
		columns: Number|String
		aspect: String
		index: Number
	render: (create, {
		props: { card, columns, aspect, index },
		data: { attrs, staticClass }
	}) ->

		# Common props
		props =
			index: index
			aspect: do ->
				return unless aspect?.indexOf ':'
				[width, height] = aspect.split ':'
				return width / height
			columns: parseInt columns
			image: card.image

		# Add props for specific card types
		Object.assign props, switch card.type

			# Link list cards
			when 'linkListCard'
				menuTitle: card.menuTitle
				links: card.links

			# Category group cards
			when 'categoryGroupCard'
				categoryGroup: card.productCategoryGroup
				categories: card.categories

			# Typical listing cards
			else
				headline: card.headline || card.title
				imageHover: card.imageHover
				imageSize: card.imageSize
				href: switch
					when card.href then card.href
					when card.uri then "/#{card.uri}"
					when card.asset?.length then card.asset[0].url
				description: card.description
				linkLabel: card.linkLabel
				soldOut: card.soldOut

		# Render the right card
		component = switch card.type
			when 'linkListCard' then LinkListCard
			when 'categoryGroupCard' then CategoryGroupCard
			else ListingCard

		# Render the component
		create component,
			props: props
			attrs: attrs
			class: staticClass

# A helper function for unpacking nested cards, like from brands
export unpackCards = (cards) -> cards.reduce (list, card) ->
	switch card.type
		when 'brand' then list.push flavor for flavor in card.flavors
		else list.push card
	return list
, []
