




















import Copyright from './copyright'
export default

	components: {
		Copyright
	}

