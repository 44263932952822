




































endpoint = 'https://maps.googleapis.com/maps/api/place/autocomplete/json'
export default

	props:
		placeholder: String
		cta: String
		requireZip: Boolean

	data: ->
		attach: null
		placeId: ''
		place: null
		input: '' # What the user has typed in
		predictions: []

	# Re-create during HMR
	mounted: ->
		@attach = @$el
		@createService() if window.google

	computed:

		error: -> switch
			when @requireZip and @place and !@place.zip
				'That address is not specific enough.'
			else ''

	watch:

		# If there is input, search, otherwise, clear the predictions list
		input: ->
			if @input then @getPredictions()
			else
				@placeId = ''
				@predictions = []

		# Lookup place details. The blur triggers final subsitution of value,
		# usually unpacking the state
		placeId: ->
			if @placeId
				@$refs.autocomplete.blur()
				@getPlaceDetail()
			else @place = null

		# Emit the place when it changes
		place: ->
			# if @requireZip and @place and !@place.zip
			# 	@place = null
			# else @$emit 'place', @place
			@$emit 'place', @place

	methods:

		# Create the autocomplete service instance. The places service needs an
		# element
		# https://stackoverflow.com/a/14345546/59160
		createService: ->
			@autocompleteService = new google.maps.places.AutocompleteService()
			placesEl = document.createElement 'div'
			@placesService = new google.maps.places.PlacesService placesEl

		# Get predictions from the input
		getPredictions: ->
			return unless @autocompleteService
			@predictions = await new Promise (resolve) =>
				@autocompleteService.getPlacePredictions {
					input: @input
					sessionToken: @getSessionToken()
					componentRestrictions: country: 'us'
				}, (predictions, status) ->
					if status == google.maps.places.PlacesServiceStatus.OK
					then resolve predictions
					else
						console.debug 'Non "OK" autocomplete response', status
						resolve []

		# Make a new session token if one doesn't exist. These get reset when the
		# detail info for a place is looked up
		getSessionToken: ->
			if @sessionToken then @sessionToken
			else @sessionToken = new google.maps.places.AutocompleteSessionToken()

		# Lookup the geometry of the place
		getPlaceDetail: ->
			return unless @placesService and sessionToken = @getSessionToken()
			@sessionToken = null # Reset the token
			@place = await new Promise (resolve) =>
				@placesService.getDetails {
					@placeId
					sessionToken
					fields: [ 'geometry', 'address_components' ]
				}, (place, status) =>
					if status == google.maps.places.PlacesServiceStatus.OK
					then resolve
						id: @placeId
						origin: true
						lat: place.geometry.location.lat()
						lng: place.geometry.location.lng()
						zip: place.address_components.find(({ types }) ->
							return 'postal_code' in types
						)?.short_name
					else
						console.debug 'Non "OK" detail response', status
						resolve null

		# Absorb key events so they don't trigger smooth scroll
		onKey: ->

