# Shared logic of all pages
import { resetBlockIndexCount } from '~/components/global/blocks/block-wrapper'
import URL from 'url-parse'
export default

	# Reset the index count
	beforeCreate: -> resetBlockIndexCount()

	# When a page mounts, init smooth scroll
	mounted: ->

		# Fire GTM pageview event
		@$gtm.push
			event: 'Page Mounted'
			pageTitle: @$metaInfo?.title


		# Listen for content changes from Craft's preview mode
		window.addEventListener 'message', @onPostMessage

		# Immediately refresh if mounting into a preview request on SSG.
		if process.static && (@$route.query['x-craft-preview'] ||
			@$route.query['x-craft-live-preview'] )
		then @$nextTick @refreshData

	# Cleanup listeners
	detstroyed: -> window.removeEventListener 'message', @onPostMessage

	methods:

		# Helper to make head tags. Passed in props are only used if explicit meta
		# values aren't found
		buildHead: ({ title, description, image }) ->

			# Calc the values
			store = @$store.state.globals
			_title = @page.metaTitle or title or @page.title
			_description = @page.metaDescription or description or
				store.metaDescription
			_image = switch
				when @page.metaImage?.length then @page.metaImage
				when image?.length then image
				else store.metaImage

			# Create the object, filtering empties
			title: _title
			meta: [
				@$metaTag 'og:title', _title
				@$metaTag 'description', _description
				@$metaTag 'og:image', _image
				@$metaTag 'robots', @page.robots?.join ', '
			].filter (tag) -> !!tag?.content

		# Handle postMessages, looking for preview content updates
		onPostMessage: ({ origin, data }) ->
			return unless origin == (new URL process.env.CMS_ENDPOINT).origin
			@refreshData() if data == 'preview:change'

		# Refetch the page content then replace the page data. Clear the
		# $payloadURL so we don't re-use cached JSON on production.
		refreshData: ->
			asyncData = @$route.matched?[0]?.components.default.options.asyncData
			return unless asyncData
			@$nuxt.$loading.start()
			data = await asyncData { ...@$nuxt.context, $payloadURL: null }
			@[key] = val for key, val of data
			@$nuxt.$loading.finish()
