###
Renders a Buy Now button by simulating a Craft Button
###
import CraftBtn from './craft-btn'
export BUY_NOW_URL = 'https://shop.kivaconfections.com'

# The raw config, which is used elsewhere
export buyNowConfig =
	label: 'Buy Now'
	href: BUY_NOW_URL
	buttonType: 'filled'
	displayConditions: ['buyNowSupported']

# Often paired with the Buy Now button
export findUsConfig =
	label: 'Find Us'
	href: '/find-us#locator'
	buttonType: 'filled'
	displayConditions: ['buyNowSupported']

# Render the buy now button
export default
	name: 'BuyNow'
	functional: true

	# GTM instrumentation
	# props: gtm: Object
	props: ['gtm', 'dynamicUrl'] # Add 'dynamicUrl' to the props


	# Make the buy button
	render: (create, { props, data }) ->
		# Determine the URL to use: dynamicUrl prop if present, otherwise the default
		url = props.dynamicUrl ? BUY_NOW_URL
		buttonConfig = {...buyNowConfig, href: url} # Use the determined URL here

		create CraftBtn, {
			...data
			class: 'buy-now'
			props: {
				button: buttonConfig
				gtm: {
					...props.gtm
					type: 'buy now button'
				}
			}
		}
