

















export default

	props:
		selected: String
		defaultTab: String

	# Update the route with the users selection
	methods: onClick: (choice) ->
		@$router.push
			path: @$route.path
			query: locator: choice

