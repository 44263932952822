










import Vue from 'vue'
import mapStyle from './map-style'
import Pin from './pin'
export default

	props:
		initialState: Object # Initial coords and zoom
		origin: Object # The place a user entered
		overview: Boolean # Show small pins
		locations: Array # List of results
		forcePinIcon: Boolean # Don't show the small dots
		loading: Boolean

	# Dictionary that will reference pin vms
	created: -> @pins = []

	# Re-create during HMR
	mounted: -> @createMap()

	# Cleanup old instances. Note, it may be old info, but I've found some posts
	# that suggest you can't actually cleanup a map instance:
	# https://stackoverflow.com/a/28281733/59160
	destroyed: ->
		@removeBoundsListeners()
		vm.$destroy() for { vm } in @pins

	computed:

		# Group origin and pins into a single array that can be watched so all
		# can be handled the same way
		allLocations: -> [ ...@locations, ...(if @origin then [@origin] else []) ]

	watch:

		# Create and remove pins as the location array changes
		allLocations: (locations) ->

			# Create new pins
			for location in locations

				# Update state of existing pins
				if pin = @pins.find ({ id }) -> id == location.id
					pin.vm.overview = @overview
					pin.vm.location = location

				# Create new pins
				else @pins.push
					id: location.id
					vm: @createPin location

			# Remove dropped pins
			@pins = @pins.filter ({ id, vm }) =>
				if locations.find (location) -> location.id == id then return true
				else
					vm.$destroy()
					vm.$el.remove()
					return false

	methods:

		# Load a new map instance
		createMap: ->
			return @$wait 10, @createMap if !window?.google

			# Create map instance
			@map = new google.maps.Map @$refs.map,
				center: @initialState
				zoom: @initialState.zoom
				mapTypeControl: false
				fullscreenControl: false
				streetViewControl: false
				scrollwheel: false
				clickableIcons: false
				styles: mapStyle

			# Wait for map to load before adding bounds listeners so they don't
			# fire automatically on the initial draw. Don't fetch the initial set
			# of data if there is an ongoing load of results
			google.maps.event.addListenerOnce @map, 'idle', =>
				@addBoundsListeners()
				@onBoundsChanged() unless @loading

		# Create bounds listeners
		addBoundsListeners: ->
			@boundsListener = @map.addListener 'bounds_changed', @onBoundsChanged
		removeBoundsListeners: ->
			@boundsListener?.remove()

		# When pan or zoom is changed, emit that bounds have changed, which triggers
		# fetching the results within the bounds.
		onBoundsChanged: -> @$emit 'bounds', @map.getBounds()

		# Fit bounds to the list of results
		fitBounds: ->
			@removeBoundsListeners()
			bounds = new google.maps.LatLngBounds()
			bounds.extend location for location in @locations
			bounds.extend @origin if @origin
			@map.fitBounds bounds
			@$defer @addBoundsListeners

		# Center on the clicked pin when it opens
		centerPin: (location) -> @map.panTo location

		# Mount a new Pin component
		createPin: (location) ->

			# Create the instance
			pin = new (Vue.extend Pin)
				parent: this
				propsData:
					initialOverview: @overview
					initialLocation: location
					map: @map
					forcePinIcon: @forcePinIcon

			# Add click listener
			pin.$on 'click', => @centerPin location

			# Mount and return it
			pin.$mount()
			return pin

