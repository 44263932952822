import algolia from 'algoliasearch/lite'

export default
	data: ->
		loading: false
		results: []
		moreExist: false
		page: 0

	mounted: ->
		# Initialize Algolia client
		await @initAlgolia()

	methods:
		algoliaLatLng: ({lat, lng}) -> "#{lat}, #{lng}"

		initAlgolia: ->
			client = await algolia process.env.ALGOLIA_APP_ID, process.env.ALGOLIA_SEARCH_KEY
			@index = await client.initIndex('calendarEvents')

		execute: (page) ->
			return unless @origin

			@loading = true

			{ hits, page, hitsPerPage, nbPages } = await @index.search '', {
				aroundLatLng: @latLngString
				aroundRadius: @range
				getRankingInfo: true
				page
				filters: "endTimestamp > #{@nowTimestamp}"
			}

			@loading = false

			# Whether or not we should keep the same results array
			if page
			then @results.push(...hits)
			else @results = hits


			@page = page
			@moreExist = page < nbPages - 1

		# Get next page of results
		loadMore: -> @execute @page + 1

		# Traditional query for one item
		query: (term, params) ->
			@loading = true

			{hits} = await @index.search term, {
				getRankingInfo: true
				...params
			}

			@loading = false

			hits?[0]
