# Commons of Netlify forms
import formValidator from './form-validator'
export default

	mixins: [ formValidator ]

	data: ->
		submitting: false
		submitted: false
		form: 'form-name': '' # No-ops

	computed:

		# Should fields be readonly
		readonly: -> @submitting or @submitted

		# A no-op that allows customizing the form data reactively
		formData: -> @form

	methods:

		# Submit to service
		submit: ->
			return if @readonly or not @valid
			@submitting = true
			try await @postToEndpoint()
			catch e then return @submitting = false
			@submitting = false
			@submitted = true

		# Do the actual post
		postToEndpoint: -> @$axios
			method: 'post'
			url: process.env.FORM_ENDPOINT
			data: new URLSearchParams(@formData).toString()
			headers: 'content-type': 'application/x-www-form-urlencoded'
