




















import DesktopHeader from '~/components/layout/desktop-header/desktop-header'
import DesktopFooter from '~/components/layout/desktop-footer'
import MobileHeader from '~/components/layout/mobile-header/mobile-header'
import MobileFooter from '~/components/layout/mobile-footer'
import PromoBar from '~/components/layout/promo-bar'
export default
	name: 'DefaultLayout'

	components: {
		DesktopHeader
		DesktopFooter
		MobileHeader
		MobileFooter
		PromoBar
	}

	computed:

		# Set the background as informed by a block's background field which is
		# relayed to vuex from smooth scroll's data-scroll-call
		background: -> @$store.state.layout.background || '#fcfbfa' # offwhite


