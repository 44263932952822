



































export default

	props: block: Object

	computed:

		# Use to link the foreground image to the first link
		firstLink: -> @block.links?[0]?.href

