

























import Location from './location'
export default

	components: {
		Location
	}

	props:
		results: Array
		overviewMode: Boolean
		noIndexNums: Boolean
		loading: Boolean
		hasAddress: Boolean
		showNoResultsMessage: Boolean


