






































import cardMixin from './card-mixin'
import HoverVisual from '~/components/global/visual/hover-visual'
import SoldOut from './sold-out/sold-out'
export default ListingCard =
	# Add comment to force update

	name: 'ListingCard'

	mixins: [ cardMixin ]

	components: {
		HoverVisual
		SoldOut
	}

	props:

		# Layout
		aspect: Number # Applied to the image
		columns:
			type: Number
			default: 3
		scaleIn:
			type: Boolean
			default: true

		# Card content
		headline: String
		image: Object | Array
		imageSize: String
		imageHover: Object | Array
		href: String

		# ListingCard entries only
		description: String
		linkLabel: String
		soldOut: Boolean

	computed:
		contain: -> @isIcon || @shouldFill

		shouldFill: -> @imageSize == 'scaledIcon'

		isIcon: -> @imageSize == 'icon'

