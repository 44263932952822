###
Detect user's zip by using IP lookup
###
import { loadScript } from './helpers'
import { LS_KEY as ZIP_LS_KEY, DETECTED_KEY } from '~/store/buy'
export default ({ store }) ->

	# If the user has explicitly set their zip, then write that to vuex and
	# abort (don't try and detect the zip)
	if (zip = localStorage.getItem(ZIP_LS_KEY)) and
		localStorage.getItem(DETECTED_KEY) != 'true'
	then return store.commit 'buy/setZip', { zip }

	# MaxMind temporarily disabled as part of:
	# https://app.asana.com/0/1194663623278345/1201341109880498/f

	# Load the MaxMind SDK
	# await loadScript 'https://geoip-js.com/js/apis/geoip2/v2.1/geoip2.js'

	# Check for zip and store it if one found
	# geoip2.insights (response) ->
	# 	return unless zip = response?.postal?.code
	# 	store.commit 'buy/setZip', { zip, detected: true }
