import Vue from 'vue'

export state = -> {} 

export mutations =

	# There will be an array of objects with unique keys representing all the
	# global values.  Reduce them into a single object and replace the state.
	set: (state, data) -> 
		globals = data.reduce ((all, set) -> { ...all, ...set }), {}
		Vue.set state, key, val for key, val of globals
