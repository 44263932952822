













export default

	props:
		rows:
			type: Array
			default: -> [['English', 'Spanish']]

