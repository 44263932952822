###
A mixin to handle shouldRender logic for components based on zip locale
Component should have a @conditions array in computed properties
###
export default
	computed:

		# VueX accessor
		userZip: -> @$store.state.buy.zip
		userSupportsBuyNow: -> @$store.state.buy.supported || false

		# Simplify condition options into more readable code
		# Conditions is expected to be overwritten by component using this mixin
		conditions: -> []
		hasConditions: -> @conditions && @conditions.length > 0
		showIfBuyNow: -> 'buyNowSupported' in @conditions
		showUnlessBuyNow: -> 'buyNowUnsupported' in @conditions

		# Should we render this component
		shouldRender: -> switch
				when !@hasConditions then true
				when @showIfBuyNow and @userSupportsBuyNow == true then true
				when @showUnlessBuyNow and @userSupportsBuyNow == false then true
				else false # Like if conditions but supported status unknown (null)
