# Deps
import Vue from 'vue'
import _kebabCase from 'lodash/kebabCase'
import _throttle from 'lodash/throttle'
import _debounce from 'lodash/debounce'
import formatDateFNS from 'date-fns/format'
import lightOrDark from '~/vendor/light-or-dark'
import memoize from 'lodash/memoize'

export stateAbbreviations = [
 'AL','AK','AS','AZ','AR','CA','CO','CT','DE','DC','FM','FL','GA',
 'GU','HI','ID','IL','IN','IA','KS','KY','LA','ME','MH','MD','MA',
 'MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND',
 'MP','OH','OK','OR','PW','PA','PR','RI','SC','SD','TN','TX','UT',
 'VT','VI','VA','WA','WV','WI','WY'
];

# Wait a tick then execute callback
export defer = (cb) -> setTimeout cb, 0

# Wait a tick then execute callback
export wait = (ms, cb) -> setTimeout cb, ms

# Throttle with order of arguments reverse
export throttle = (delay, cb) -> _throttle cb, delay


# Debounce with order of arguments reverse
export debounce = (delay, cb) -> _debounce cb, delay

# Filter an array to just non-empties
export nonEmpty = (array) -> array.filter (val) -> !!val

# Format a date (as a string) into a terse form
export smallDate = (date) -> formatDateFNS new Date(date), "MM.dd.yyyy"

# Pad a number with 0s
export padNum = (num, size = 2) -> String(Math.round(num)).padStart(size, '0')

# Nl2br function, https://stackoverflow.com/a/784547/59160
export nl2br = (str) -> str.replace /(?:\r\n|\r|\n)/g, '<br>'

# Export validator rules
export validators =
	email: (val) -> /^\S+@\S+\.\S+$/.test(val) || 'Not a valid email'
	required: (val) -> !!val?.trim() || 'This field is required'
	zip: (val) -> /^\d{5}$/.test(val) || 'Not a valid zip code'
	fileRequired: (val) -> !!val || 'File is required'
	state: (val) -> (!!val && stateAbbreviations.includes val.toUpperCase()) || 'Please provide two letter state abbreviation'
	birthdate: (val) -> 
		return 'Birthdate is required' unless val

		# Parse the input date
		birthDate = new Date(val)

		# Get today's date
		today = new Date()

		# Calculate the age
		age = today.getFullYear() - birthDate.getFullYear()
		monthDifference = today.getMonth() - birthDate.getMonth()

		if monthDifference < 0 || (monthDifference == 0 && today.getDate() < birthDate.getDate())
			age--

		# Check if age is 21 or older
		if age >= 21 then true else 'Must be at least 21 years old'
	# New validators for day, month, and year
	day: (val) ->
		if !val? then return 'Day is required'
		# if val.length > 2 then return 'Day must be at most 2 digits'
		day = parseInt(val)
		if isNaN(day) || day < 1 || day > 31
			return 'Invalid day'
		else
			return true

	month: (val) ->
		if !val? then return 'Month is required'
		if val.length > 2 then return 'Month must be at most 2 digits'
		month = parseInt(val)
		if isNaN(month) || month < 1 || month > 12
			return 'Invalid month'
		else
			return true

	year: (val) ->
		if !val? then return 'Year is required'
		if val.length > 4 then return 'Year must be at most 4 digits'
		year = parseInt(val)
		currentYear = new Date().getFullYear()
		if isNaN(year) || year < 1900 || year > currentYear
			return 'Invalid year'
		else
			return true

# Make a helper for detecting darkness
export isDark = (color) -> if color then lightOrDark(color) == 'dark' else false

# Helper for makking meta tags
export metaTag = (key, val, keyAttribute = null) ->

	# Make the key attribute
	unless @keyAttribute
		keyAttribute = if key.match /^og:/ then 'property' else 'name'

	# Get image from Craft's array
	if key == 'og:image' and Array.isArray val
	then val = val[0]?.url || val[0]?.w1920

	# Strip HTML by default, so WYSIWYG values can be passed in
	# https://stackoverflow.com/a/5002161/59160
	val = val?.replace /<\/?[^>]+(>|$)/g, ''

	# Return object
	hid: key
	"#{keyAttribute}": key
	content: val

# Add mixins at the app level
# https://github.com/nuxt/nuxt.js/issues/1593#issuecomment-384554130
export extendApp = (app, mixin) ->
	app.mixins = [] unless app.mixins
	app.mixins.push mixin

# Mount a component on the body, like a modal, and return the mounted component
# instance. The "component" argument should be a Vue component instance, like
# returned from importing a single file component.
export mountOnBody = (component) -> new Promise (resolve) ->
	mount = ->
		unless window.$nuxt then return wait 50, mount
		vm = new (Vue.extend component)(parent: window.$nuxt.$root)
		vm.$mount()
		document.body.appendChild vm.$el
		resolve vm
	mount()

# Create a script tag and watch for it to load. Don't load the same script
# twice.
export loadScript = memoize (src, { defer = false, async = false } = {}) ->
	new Promise (resolve, reject) ->
		script = document.createElement 'script'
		script.onload = resolve
		script.onerror = reject
		script.src = src
		script.defer = true if defer
		script.async = true if async
		document.head.appendChild script

fixAlgoliaDate = (ISODate) ->
	# Restructure ISO date to universal form for Safari
	fixedDay = ((ISODate.replaceAll '-', '/').split '.')[0]

# Format date in web friendly string
export formatDate = (ISODate) ->
	algoliaDateFormat = /\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}.\d{6}/

	ISODate = fixAlgoliaDate ISODate if algoliaDateFormat.test ISODate
	formatDateFNS new Date(ISODate), "MM.dd.yyyy , h:mm a"

# Inject utils
export default ({ error }, inject) ->

	# Make 404 response
	inject 'notFound', -> error
		statusCode: 404
		message: 'Page not found'

	# Inject exported functions
	inject 'defer', defer
	inject 'wait', wait
	inject 'kebab', _kebabCase
	inject 'nonEmpty', nonEmpty
	inject 'debounce', debounce
	inject 'smallDate', smallDate
	inject 'padNum', padNum
	inject 'nl2br', nl2br
	inject 'validators', validators
	inject 'isDark', isDark
	inject 'metaTag', metaTag
	inject 'loadScript', loadScript
	inject 'formatDate', formatDate
