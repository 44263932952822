































export default

	props:
		event: Object
		latLng: Object

	computed:
		distance: ->
			meters = @event._rankingInfo.geoDistance
			# conversion for meters to miles (nearest tenth round)
			Math.round(meters * 0.000621371 * 10) / 10

		directions: ->
			return if !@event.location
			"https://www.google.com/maps?daddr=#{encodeURI @event.location}"

		eventDetailURL: ->
			return @event.uri if !@latLng
			@event.uri+"?lat=#{@latLng.lat}&lng=#{@latLng.lng}"

		copyToCalendar: ->
			#Grab the EID from the calendar link and slap it onto a copy event url
			if @event.calendarLink
				link = "https://calendar.google.com/calendar/u/0/r/eventedit/copy/" +
					@event.calendarLink.split("eid=")[1]


	methods:
		formatTime: (datetime) -> @$formatDate(datetime.date) if datetime?.date


