





















import FooterContainer from './footer/footer-container'
import NavList from './footer/nav-list'
import NewsletterSignup from '~/components/global/newsletter-signup'
import SocialLinks from '~/components/global/social-links'
export default

	components: {
		FooterContainer
		NavList
		NewsletterSignup
		SocialLinks
	}

	computed:

		# Vuex acccessors
		nav: -> @$store.state.navigation.footer

