import { render, staticRenderFns } from "./primary-nav.vue?vue&type=template&id=976d6ce0&scoped=true&lang=pug&"
import script from "./primary-nav.vue?vue&type=script&lang=coffee&"
export * from "./primary-nav.vue?vue&type=script&lang=coffee&"
import style0 from "./primary-nav.vue?vue&type=style&index=0&id=976d6ce0&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "976d6ce0",
  null
  
)

export default component.exports