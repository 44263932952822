






















import Modal from './modal'
import NewsletterSignup from '~/components/global/newsletter-signup'
export default

	components: {
		Modal
		NewsletterSignup
	}

	data: -> success: false

	computed:

		# VueX
		globals: -> @$store.state.globals

