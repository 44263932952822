###
Hydrate the zip from localStorage and trigger looking up whether zip supports
buying directly
###
import { extendApp, wait } from './helpers'
import { LS_KEY } from '~/store/buy'
import { localStorage } from './local-storage'
export default ({ app, store }) ->

	# When the zip is updated, check for support for buy now
	store.watch ((state) -> state.buy.zip),
		(zip) ->
			await store.dispatch 'buy/checkSupported', zip
