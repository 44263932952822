
























import ArticleBody from '~/components/global/article-body'
export default

	components: { ArticleBody }

	props: block: Object

