# Make a btn instance, wrapped in an anchor tag, from a Craft Button entry
import Btn from './btn'
import mapKeys from 'lodash/mapKeys'
import shouldRender from '../../../mixins/should-render'
export default
	name: 'CraftBtn'
	inject: blockType: default: null
	mixins: [ shouldRender ]
	props:
		button: Object
		gtm: Object

	computed:
		# Conditions to be used for shouldRender mixin
		conditions: -> @button.displayConditions || []

		# The url of the button
		href: ->
			if assetUrl = @button.asset?[0]?.url
			then assetUrl else @button.href

	render: (create) ->

		# Make the button
		btn = create Btn,
			props:
				type: @button.buttonType
				color: @button.buttonColor
		, @button.label

		# Conditionally wrap in "a" container
		linkedBtn = unless @href then btn
		else create 'smart-link',
			props: to: @href

			# Automatically instrument all btns
			attrs: {
				'data-gtm': 'link_click'
				'data-gtm-location': @blockType
				'data-gtm-type': 'craft button'
				...mapKeys @gtm || {}, (val, key) -> "data-gtm-#{key}"
			}

		# The wrapped button instance
		, [ btn ]

		# Conditionally wrap in a transition if there are display conditions
		unless @hasConditions then linkedBtn
		else create 'transition',
			props: name: 'fade'
		, if @shouldRender then [ linkedBtn ]
