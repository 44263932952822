


















# Keeps track of the block index for the current page. The first block is 0,
# second is 1, etc.  Gets reset as part of the beforeCreated() hook of all
# pages.
blockIndexCount = 0

# The component
export default

	props:
		block:
			type: Object
			default: -> {} # May be empty when used explicitly from pages like flavor

	provide: ->
		blockIndex: blockIndexCount++
		blockIsDark: @dark
		blockBackground: @block.background
		blockType: @block.__typename?.match(/_([a-z]+)_/i)?[1]

	computed:

		# Automatically add hide classes from block config
		classes: ->
			classes = [ 'block', 'dark-block': @dark ]
			if @block.breakpoints?.length
				classes.push 'hide-desktop' unless @block.breakpoints.includes 'desktop'
				classes.push 'hide-mobile' unless @block.breakpoints.includes 'mobile'
			return classes

		# Determine if the background color is dark
		dark: -> @$isDark @block.background

		# Set background color
		backgroundStyles: -> background: @block?.background

	methods:

		# Make the stringifyied object that will get sent to smooth scroller
		makeScrollCall: (block) -> JSON.stringify
			type: 'block'
			payload: background: block.background || null

# Reset the block index count
export resetBlockIndexCount = -> blockIndexCount = 0

