





































































































































import Modal from './modal'
import formValidator from '~/mixins/form-validator'
export LS_KEY = 'age-gate:passed'
export default

	mixins: [ formValidator ]

	components: {
		Modal
	}

	data: -> 
		years: []
		days: []
		state: 'initial'
		form:
			state: null
			birthDay: ''
			birthMonth: ''
			birthYear: ''
		showStateMissingMessage: false
		validation: 
			day: [@$validators.required, @$validators.day]
			month: [@$validators.required, @$validators.month]
			year: [@$validators.required, @$validators.year]

	watch:
		'form.state': (newState, oldState) ->
			if newState
				@showStateMissingMessage = false
		'form.birthMonth': 'updateDays'
		'form.birthYear': 'updateDays'

	computed:

		# VueX
		globals: -> @$store.state.globals

		fullBirthdate: ->
			year = @form.birthYear
			month = @form.birthMonth
			day = @form.birthDay

			# Ensure month and day are in two-digit format
			month = "0#{month}" unless month.length == 2
			day = "0#{day}" unless day.length == 2

			"#{year}-#{month}-#{day}"

		isUnderTwentyOne: ->
			today = new Date()
			birthDate = new Date(@fullBirthdate)
			age = today.getFullYear() - birthDate.getFullYear()
			m = today.getMonth() - birthDate.getMonth()
			if m < 0 or (m == 0 and today.getDate() < birthDate.getDate())
				age--
			age < 21


	methods:
		# Remember their choice and close it
		agree: ->
			validationResult = @$validators.birthdate(@fullBirthdate)
			if validationResult is true
				if not @form.state
					@showStateMissingMessage = true
					return
				localStorage.setItem('selectedState', @form.state)
				ageCheck = @isUnderTwentyOne
				if not ageCheck
					redirectState = @$store.state.globals.ageGateRedirectState.find (state) => state.value == @form.state
					@$localStorage.setItem LS_KEY, true
					@$emit 'passed'
					if redirectState
						window.location.href = @$store.state.globals.ageGateUrlRedirect
					else
						@$refs.modal.close()
				else
					# Here, you should inform the user that they do not meet the age requirement
					# This could be through a dialog, a toast message, or any other UI element that fits your design
					alert("Sorry, you must be 21 years of age or older to access this site.")
			else
				# Handle validation failure (e.g., show error message)

		# Show sorry message
		disagree: -> @state = 'disagreed'
		updateDays: ->
			month = @form.birthMonth
			year = @form.birthYear
			daysInMonth = 31
			if month in ['04', '06', '09', '11']
				daysInMonth = 30
			else if month == '02'
				if year % 4 == 0 and (year % 100 != 0 or year % 400 == 0)
					daysInMonth = 29
				else
					daysInMonth = 28

			@days = (if d < 10 then "0" + d else d.toString() for d in [1..daysInMonth])

		generateYears: ->
			currentYear = new Date().getFullYear()
			yearsRange = [currentYear - 100 .. currentYear]
			@years = yearsRange.reverse()

	mounted: ->
		@updateDays()
		@generateYears()
		# Retrieve and set the stored state if it exists
		storedState = localStorage.getItem('selectedState')
		if storedState
			@form.state = storedState


