








































import Selector from '~/components/global/selector'

import BasicForm from './form-options/basic-form'
import CareerInquiry from './form-options/career-inquiry'
import PressInquiry from './form-options/press-inquiry'
import ProductQuestion from './form-options/product-question'
import ProductComplaint from './form-options/product-complaint'
import AccountInquiry from './form-options/account-inquiry'

export forms = [
	'Select Inquiry Type'
	'KIVA LOVE'
	'Career Inquiry'
	'Marketing/Creative Inquiry'
	'Press Inquiry'
	'Product Question'
	'Product Complaint'
	'Account or Supplier Inquiry'
	'Miscellaneous'
]


export default
	name: "MultipartContactForm"

	components: {
		Selector
		BasicForm
		CareerInquiry
		ProductQuestion
		ProductComplaint
		AccountInquiry
	}

	props:
		block: Object

	data: ->
		subjectChoice: forms[0]
		subjects: forms

	mounted: ->
		# If a form is in out url, select that form
		if val = @$route.query.form
			@subjectChoice = val
			contactAnchor = document.querySelector "[data-anchor='contact']"


	watch:
		subjectChoice: (curr, prev) ->

			# Update our path with the current selected form
			return if curr == @$route.query.form
			if curr is "Select Inquiry Type"
			then @$router.replace query: {}
			else @$router.replace query: form: curr


