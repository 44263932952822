


























import padStart from 'lodash/padStart'
export default

	computed: 

		# VueX accessors
		navigation: -> @$store.state.navigation

		# Massage into hierarchy for panels, creating a new array with clean
		# objects
		options: -> @navigation.header.reduce (options, option) => 

			# Add child links in a consistent place
			options.push Object.assign {}, option, links: do => switch
				when option.specialType == 'headerProducts' then @products
				when option.type == 'navigationCategory' then option.children

			# Add brands to the list
			if option.specialType == 'headerProducts'
				options.push 
					id: 'brands'
					title: 'Brands'
					links: @brands
			
			return options
		, []
			
		# Make products links
		products: -> 
			productsCount = 0
			links = @navigation.brands.reduce (links, brand) ->
				for product in brand.products
					productsCount += product.flavors.length
					links.push
						id: "#{brand.id}#{product.id}"
						title: "#{brand.title} #{product.title}"
						href: product.href
				return links
			, []

			# Add all products link
			links.push
				id: 'all'
				title: "All Products"
				href: '/products'
			return links

		# Make list of brands
		brands: -> @navigation.brands

