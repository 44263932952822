import { render, staticRenderFns } from "./standard-menu.vue?vue&type=template&id=543fbdf4&scoped=true&lang=pug&"
import script from "./standard-menu.vue?vue&type=script&lang=coffee&"
export * from "./standard-menu.vue?vue&type=script&lang=coffee&"
import style0 from "./standard-menu.vue?vue&type=style&index=0&id=543fbdf4&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "543fbdf4",
  null
  
)

export default component.exports