



















import MobileNav from './mobile-nav'
import MenuFooter from './menu-footer'
export default

	components: {
		MenuFooter
		MobileNav
	}


