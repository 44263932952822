























import Event from './event'

export default

	components: {
		Event
	}

	props:
		results: Array
		loading: Boolean
		showNoResultsMessage: Boolean
		latLng: Object


