import kebabCase from 'lodash/kebabCase'

getNavChildren = (navigation, category) ->
	navigation
		.find (nav) -> nav.category == category
		.children

export state = ->
	header: []
	footer: []
	brands: []
	productTypes: []

export mutations =

	# Store fetched data
	set: (state, { navigation, brands, productTypes }) ->

		# Simple setters
		state.header = getNavChildren navigation, 'header'
		state.footer = getNavChildren navigation, 'footer'

		# Add links to product types
		state.productTypes = productTypes.map (product) ->
			{ ...product, href: "/products?product-type=#{product.slug}" }

		# Nest flavors into brands and add hrefs for all
		state.brands = brands.map (brand) ->
			brand.href = "/#{brand.uri}"

			# Loop through the flavors of the brand
			brand.products = brand.flavors.reduce (products, flavor) ->

				# Make the flavor href
				flavor = { ...flavor, href: "/#{flavor.uri}" }

				# If the products array I'm creating already has this product type...
				if product = products.find (product) ->
					product.slug == flavor.productType[0]?.slug

				# ... then add this flavor to it
				then product.flavors.push flavor

				# ... else create a new product with this flavor in it
				else if product = flavor.productType[0]
					products.push Object.assign {}, product,
						href: "/products?\
							brand=#{brand.slug}&\
							product-type=#{product.slug}&\
							reset"
						flavors: [ flavor ]

				# Finish up
				return products # Return the updated products list
			, [] # End the reduce
			return brand # Return the mapped brand
