
































import { wait } from '~/plugins/helpers'
export default

	props:
		type:
			type: String
			default: 'compact'
		closeable:
			type: Boolean
			default: true

	data: -> open: true


	methods:

		# Remove the modal
		close: -> @open = false

		# Remove it after the transition ends
		remove: ->
			@$destroy()
			@$el.remove()

