




























export default

	props: block: Object

	computed:

		# CSS classes
		classes: -> [
			@$kebab @block.orientation,
			'has-texture': @block.texture.length
		]

