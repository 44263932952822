












import BlockList from '~/components/global/blocks/block-list'
import pageMixin from '~/mixins/page-mixin'
import getTower from '~/queries/pages/tower.gql'
export default

	mixins: [ pageMixin ]

	components: { BlockList }

	props: ['error']

	data: -> page: null

	# Title / SEO
	head: ->
		return unless @page
		title: (title = (@page.metaTitle or @page.title))
		meta: [
			@$metaTag 'og:title', title
			@$metaTag 'description', @page.metaDescription
			@$metaTag 'og:image', @page.metaImage
			@$metaTag 'robots', @page.robots?.join ', '
		].filter (tag) -> !!tag?.content

	# Handle differently based on the status code of the error
	mounted: ->
		switch @error?.statusCode
			when 404 then @loadPage 404
			else
				@showSentryDialog()
				@loadPage 500

	methods:

		# Show the sentry dialog
		# https://docs.sentry.io/enriching-error-data/user-feedback/?platform=browser
		showSentryDialog: -> @$defer => @$sentry?.showReportDialog()

		# Get the tower data
		loadPage: (slug) ->
			@page = await @$craft.getEntry
				query: getTower
				variables:
					type: 'tower'
					slug: slug

