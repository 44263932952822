###
Make an axios client for looking up zip on Grassdoor
###
import axios from 'axios'

# We proxy this endpoint to https://api.grassdoor.com
GRASSDOOR_ENDPOINT = '/grassdoor/api/v1/location/'

# Check if a Zip is supported by grassdoor
export isZipSupported = (zip) ->
	try { data } = await axios.get GRASSDOOR_ENDPOINT + zip
	catch e # Ignore errors
	return !!data?.success

# Inject into app
export default ({}, inject) ->
	inject 'grassdoor', { isZipSupported }
