













import DownHr from '~/components/global/dividers/down-hr'
import Location from './location'
export default

	components: {
		DownHr
		Location
	}

	props: location: Object

	mounted: ->

		# Move parent pin to the end of the list so the tooltip overlaps others
		@moveLast()

		# Fire again after the map pan finishes in case a new pin was added and
		# it would overlap the map
		@$wait 500, @moveLast

		# Prevent clicks within the tooltip from bubbling out and preventing the
		# click outside handling from working
		google.maps.OverlayView.preventMapHitsFrom @$el

	methods:

		# Move parent pin to the end of the list
		moveLast: ->
			$pin = @$el.closest('.pin')
			$pin.parentElement?.appendChild $pin

