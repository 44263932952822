# Map Craft image objects into params for visual
import Visual from 'vue-visual'
import 'vue-visual/index.css'
export default
	name: 'CraftVisual'
	functional: true

	# Get the index of the block this may be in
	inject: blockIndex: default: undefined

	props:

		# A Craft image object with width, height, etc
		image: Object|Array

		# Passthrough the aspect ratio
		aspect: Number
		natural: Boolean
		noUpscale: Boolean
		objectFit: String

		# CSS image sizes rules
		sizes: String

		# Add the scale-in effect on load
		scaleIn: Boolean

	render: (create, { props, injections, data, children }) ->

		# If no image but an aspect, still create the Visual instance which
		# becomes a placeholder space
		unless image = getImageObject props.image
			return create Visual, {
				...data
				props: aspect: props.aspect
			}

		# Figure out the aspect unless disabled by natural
		aspect = props.aspect or image.width / image.height unless props.natural

		# Passthrough out the width and height if aspect == false
		{ width, height } = if props.natural then image else {}

		# Is this a critical image, like probably above the fold
		isCritical = injections.blockIndex < 2

		# Instantiate a Visual instance
		create Visual, {
			...data
			props: {

				# Image
				image: image.w1920
				sizes: props.sizes
				...(if props.natural then {} else {
					srcset: makeSrcset image, webp: false
					webpSrcset: makeSrcset image, webp: true
				})

				# Layout
				aspect
				width
				height
				maxWidth: if props.noUpscale then width
				objectPosition: makeObjectPosition image
				objectFit: props.objectFit

				# Loading
				lazyload: !isCritical
				intersectionOptions: rootMargin: '50% 0% 50% 0%'
				transition: if isCritical then '' else props.transition
				placeholderColor: '#f9f7f3' if image.mimeType == 'image/jpeg'

				# Misc
				alt: image?.title
			}

			# Add scale in reveal class
			# class: unless props.scaleIn then data.class else [
			# 	data.class
			# 	'scale-in-reveal'
			# ]

			# Add scroll responer classes if scaling in
			attrs: unless props.scaleIn then data.attrs else {
				...data.attrs
				'data-scroll': true
				'data-scroll-call': JSON.stringify type: 'visual'
			}

		# Slot children
		}, children

# Craft returns images in an array, so get the first item in the list
export getImageObject = (image) ->
	if Array.isArray image
		if image.length
		then return image[0]
		else return null
	return image

# Make a CSS background position value
makeObjectPosition = (image) ->
	return '50% 50%' unless image?.focalPoint
	"#{image.focalPoint[0] * 100}% #{image.focalPoint[1] * 100}%"

# The srcset values need to match those used in transforms in the query
srcsetSizes = [1920,1440,1024,768,425,210]
export makeSrcset = (image, { webp } = {}) ->
	srcsetSizes.map (size) ->
		srcKey = "w#{size}"
		srcKey += '_webp' if webp
		"#{url} #{size}w" if url = image[srcKey]
	.filter (val) -> !!val # Webps will be empty for svgs
	.join ','
