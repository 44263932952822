













































































import formMixin from './form-mixin'
import Checkbox from '~/components/global/filterable-list/checkbox'

export default
	name: "BasicForm"

	mixins: [ formMixin ]

	components: {
		Checkbox
	}


	inject: ['blockIsDark']

	props:
		block: Object
		formName: String
		newsletter: Boolean

	data: ->
		valid: false
		form:
			'form-name': @formName
			name: ''
			email: ''
			message: ''
			newsLetterOptIn: []
		validation:
			name: [ @$validators.required]
			email: [ @$validators.required, @$validators.email ]
			message: [ @$validators.required ]
		submitting: false
		submitted: false

