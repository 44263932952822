






























import PinTooltip from './pin-tooltip'
Overlay = null # Will store the class once created
export default

	components: {
		PinTooltip
	}

	# Used to hide index num if we're not showing results list
	inject: noResultsList: default: false

	props:
		initialOverview: Boolean # Use the small dot icon
		initialLocation: Object # The location/result this represents
		map: Object # Google map object
		forcePinIcon: Boolean

	# Overview/location will be updated externally, but we get the initial value
	# via props
	data: ->
		open: false
		overview: @initialOverview
		location: @initialLocation

	# Use Google's Overlay system to move this marker into the map
	mounted: ->
		@overlay = new (@overlayClassFactory())
			map: @map
			location: @location
			el: @$el

	# The pin was remove from the dom
	destroyed: -> @overlay?.destroy()

	computed:

		# Is this an origin pin
		origin: -> !!@location.origin

		# Decide which pin to show
		shapeClass: -> switch
			when @origin then 'icon-pin-origin'
			when @overview and not @forcePinIcon then 'icon-dot'
			else 'icon-pin'

	watch:

		# Fire open event
		open: -> @$emit 'open', @location if @open

	methods:

		# The overlay factory has to be defined and created at runtime because it
		# needs its prototype set to google.maps.OverlayView before the other
		# prototypes are added but THAT class is loaded client side.
		overlayClassFactory: ->
			return Overlay if Overlay
			Overlay = OverlayBody.constructor
			Overlay.prototype = new google.maps.OverlayView()
			for property in ['onAdd', 'draw', 'onRemove', 'destroy']
				Overlay.prototype[property] = OverlayBody[property]
			return Overlay

		# Set open state and listen for click's outside
		onPinClick: ->
			@$emit 'click'
			return if @origin or @open # Conditionally open
			@open = true
			@$defer => @onOpenListener = @map.addListener 'click', @onClose

		# On close click
		onClose: ->
			return unless @open
			@open = false
			@onOpenListener?.remove()

# These methods get merged into the Overlay class we'll create at runtime
OverlayBody =

	# DI and hydrate with map
	constructor: ({ map, @el, @location }) -> @setMap map

	# Add element to the map. Place origin pins behind others
	onAdd: ->
		plane = @getPanes().overlayMouseTarget
		if @location.origin then plane.prepend @el else plane.appendChild @el

	# Position it
	draw: ->
		point = new google.maps.LatLng @location.lat, @location.lng
		{ x, y } = @getProjection().fromLatLngToDivPixel point
		@el.style.left = "#{x}px"
		@el.style.top = "#{y}px"

	# Called externally to trigger destruction.  Vue will handle removing the
	# children and cleaning everything else up
	destroy: -> @setMap null

	# Required but unnecessary
	onRemove: ->

