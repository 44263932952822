

















export default

	props:
		block:
			type: Object
			default: -> {} # Not defined when used from pages

	computed:

		classes: -> "height-#{@block?.height || 'standard' }"

