















import TweenNum from 'vue-tween-number'
export default

	components: { TweenNum }

	props: 
		title: String
		total: Number

