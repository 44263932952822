






































import axios from 'axios'

# Child components
import Geocoder from '../locator/geocoder'
import Selector from '~/components/global/selector'
import Results from './results'

# Libraries
import getDistance from 'geolib/es/getDistance'
import convertDistance from 'geolib/es/convertDistance'
import inViewportMixin from 'vue-in-viewport-mixin'
import algoliaSearchMixin from '~/mixins/algolia-search'

export default
	components: {
		Geocoder
		Selector
		Results
	}

	mixins: [ inViewportMixin, algoliaSearchMixin ]

	provide: -> { @noResultsList }

	props: block: Object

	data: ->
		origin: null # The looked up location Place
		resultsOrigin: null

		distance: 'DISTANCE'
		distanceChoices: [
			'DISTANCE'
			'10 MILES'
			'50 MILES'
			'100 MILES'
			'200 MILES'
		]

	mounted: ->
		# Load JS library onto window for geocoder
		await @loadGoogleMaps()

		# Create the autocomplete service instance
		@$refs.geocoder.createService()


	computed:

		# Convert object into string for Algolia Search
		latLngString: -> @algoliaLatLng @origin if @origin

		latLng: -> {lat: @origin.lat, lng: @origin.lng} if @origin

		# Conver selected miles to meters for search
		range: ->
			miToM = 1609.3
			switch @distance
				when '10 MILES'
					Math.round(10 * miToM)
				when '50 MILES'
					Math.round(50 * miToM)
				when '100 MILES'
					Math.round(100 * miToM)
				when '200 MILES'
					Math.round(200 * miToM)

		# Display no results message if an origin has been entered
		noResults: -> @origin && !@results.length

		nowTimestamp: ->
			day = new Date()
			Math.floor(day / 1000)

	watch:

		# Update the origin used in computed props when the results change
		results: -> @resultsOrigin = @origin

		# If distance changes, update search
		range: ->
			await @execute() if @origin

	methods:

		# Load the Google Maps API
		loadGoogleMaps: -> new Promise (resolve) =>
			return resolve() if window.google?.maps
			window.googleMapsLoaded = resolve
			src = "https://maps.googleapis.com/maps/api/js?\
				libraries=places&\
				key=#{process.env.GOOGLE_MAPS_API_KEY}&\
				callback=googleMapsLoaded"
			await @$loadScript src, defer: true

		# Update the origin with the looked up place and fetch results
		onPlace: (@origin) ->
			@execute()


