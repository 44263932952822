




























export default

	props:

		# A Craft image object with width, height, etc
		image: Object|Array
		hover: Object|Array

		# Other, basic props
		aspect: Number
		sizes: String
		scaleIn: Boolean
		natural: Boolean
		noUpscale: Boolean
		objectFit: String

	computed:

		# Don't render the hover image on touch devices, it won't be used
		noHover: -> Modernizr?.touchevents or !@hover

