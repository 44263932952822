




















































import Filters from './filters'
import ExpansionToggle from './expansion-toggle'
import ResultsGroup from './results-group'
import debounce from 'lodash/debounce'
import intersection from 'lodash/intersection'
import orderBy from 'lodash/orderBy'
import pickBy from 'lodash/pickBy'
import mapKeys from 'lodash/mapKeys'
import mapValues from 'lodash/mapValues'
import camelCase from 'lodash/camelCase'
export default

	components: {
		Filters
		ExpansionToggle
		ResultsGroup
	}

	props:
		title: String
		groupBy: String
		filters: Array
		source: Array
		expandableGroups: Boolean # If true, groups start collapsed
		noResultsMessage: String

	data: ->
		choices: {}
		applyBounce: false
		queryParsed: false

	# Use the filters to set the initial state of the choices object
	created: -> @reset()

	# Apply deep linked query on load
	mounted: ->
		@setChoicesFromRoute()
		@queryParsed = true

		# Open accordions with that selection after a delay (looks better)
		@$defer => @$refs.desktopFilters?.openSelected()

	computed:

		# The total count of results
		count: -> @results.length

		# Filter the careers
		results: -> @source.filter (result) =>

			# Loop through choices
			for categorySlug, choices of @choices

				# If no selections don't apply
				continue if choices.length == 0

				# Get an array of the result's attributes for this category
				attributes = result.filterAttributes[categorySlug]

				# Reject if there is no common ground between user choices and result
				# attribtues for this category
				return false unless intersection(choices, attributes).length

			# If no rejection, allow this result through
			return true

		# Group the results by product type
		groupedResults: ->
			groups = @results.reduce (groups, result) =>
				groupBySlug = result.filterAttributes[@groupBy][0]

				# Has the product type group already been created?
				if group = groups.find ({ slug }) -> slug == groupBySlug

				# ... if so, add the result to it
				then group.results.push result

				# If not, create the group and add the result
				else
					groupOption = @filters
					.find ({ slug }) => slug == @groupBy
					.options.find ({ slug }) => slug == groupBySlug
					groups.push { ...groupOption, results: [ result ] }

				# Return the mutated groups array
				return groups
			, []

			# Order the groups by their order in the filter list
			groupByOptions = @filters.find(({ slug }) => slug == @groupBy).options
			return orderBy groups, (group) =>
				groupByOptions.findIndex ({ slug }) -> slug == group.slug

		# Auto open groups when there is only one group in the results
		forceOpenGroups: -> @expandableGroups and @groupedResults.length == 1

		# Make the query params for the current choices
		queryParams: ->
			params = @choices
			params = pickBy params, (val) -> val.length
			params = mapValues params, (val) -> val.join ','
			mapKeys params, (val, key) => @$kebab key

		# Convert the query back into the format expected in state
		parsedQuery: ->
			params = @$route.query
			params = mapValues params, (val) -> val?.split ','
			choices = mapKeys params, (val, key) => camelCase key

			# Removes any query params that aren't for known filters
			pickBy choices, (val, key) => @filters.find ({ slug }) => slug == key

	watch:

		# When choices change, apply a class to trigger light effects to indicate
		# a UI change.
		choices:
			deep: true
			handler: (val) ->
				@applyBounce = true
				clearTimeout @bounceId
				@bounceId = @$wait 400, => @applyBounce = false

		# Update the route as query params change
		queryParams: ->
			return unless @queryParsed
			@$router.replace
				path: @$route.path
				query: @queryParams
				hash: @$route.hash
			.catch (e) -> # Silently catch "NavigationDuplicated" errors

		# Update the choices as query params change
		$route: -> @setChoicesFromRoute()

		# Reset the accordion state if null (as opposed to undefined)
		'$route.query.reset': (val) ->
			if val == null
				@$defer => @$refs.desktopFilters?.openSelected()

	methods:

		# Reset the choices state
		reset: -> @$set @choices, category.slug, [] for category in @filters

		# Set the choices from the route state
		setChoicesFromRoute: ->
			@reset()
			@choices = { ...@choices, ...@parsedQuery }


