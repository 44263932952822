




















import NewsletterSignup from '~/components/global/newsletter-signup'
import SocialLinks from '~/components/global/social-links'
export default

	components: {
		NewsletterSignup
		SocialLinks
	}

	props: block: Object

