
















export default

	props: height: Number

	data: ->
		forceBackground: false
		scrollingUp: false
		scrollY: 0

	mounted: ->
		@forceBackground = Modernizr.ie
		window.addEventListener 'scroll', @onScroll

	destroyed: -> window.removeEventListener 'scroll', @onScroll

	computed:

		# Is the page currently loading
		loading: -> @$store.state.loading

		# If using smooth scrolling, make this feel like it scrolls off screen
		# naturally
		transformOffset: ->
			if (@scrollingUp and @tweenOffset) or @loading then 0
			else -1 * Math.min @height, @scrollY

		# Toggle the transformation duration time so there is no animation when the
		# offset is lockstep with the scroll
		tweenOffset: -> @scrollingUp or @scrollY > @height

		# Always show the background color when down the page
		showBackground: -> @loading or @scrollY > @height or @forceBackground

		# Build CSS
		styles: -> {
			...@$store.getters["promo-bars/offsetStyles"]
			transform: "translateY(#{@transformOffset}px)"
		}
		classes: ->
			'tween-transfrom': @tweenOffset
			'show-bkgd': @showBackground

	methods:
		# Store scroll variables; added to allow functionality without smooth-scroller
		onScroll: ->
			now = window.scrollY
			@scrollingUp = now != 0 and now < @scrollY
			@scrollY = now

