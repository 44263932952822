

























import PrimaryButton from './primary-button'
export default

	components: {
		PrimaryButton
	}

	props:
		title: String
		active: Boolean

	data: ->
		open: false
		align: 'left'

	computed:

		layout: -> @$store.state.layout

		# Don't let the window go pass the inside of the gutter
		maxRight: -> @layout.viewport.width - @layout.header.gutter

	watch:

		# Decide whether it should be right aligned so it doesn't leave window. Only
		# calculating on open so there isn't a flip when it closes.  This is
		# affected by the offset from the transform in the transition so if I wanted
		# to make it even more accurate, I'd make the right value the sum of it's
		# width and the left offset of the menu-link itself.
		open: ->
			return unless @open
			@align = 'left' # Reset calculation
			@$nextTick ->
				rightPos = @$refs.menu.getBoundingClientRect().right
				@align = if rightPos <= @maxRight then 'left' else 'right'

		# When the route changes, close menu if open
		$route: -> @open = false if @open


