

























import Selector from '~/components/global/selector'
export default

	components: { Selector }

	props:
		categories: Array
		activeCategory: String
	
	computed:

		# Make the categories list v-select-friendly and add view all
		items: -> 
			items = @categories.map (category) ->
				text: category.title
				value: category.slug
			items.push 
				text: 'Show All'
				value: null
			return items

