






























# Deps
import Vue from 'vue'
import FilterableList from '~/components/global/filterable-list/filterable-list'
import getProducts from '~/queries/blocks/products.gql'

# The filter categories to expect and the order to show them
filterCategories = [
	{ slug: 'brand', title: 'Brand' }
	{ slug: 'productType', title: 'Product' }
	{ slug: 'availability', title: 'Availability by State' }
	{ slug: 'activeIngredient', title: 'Active Ingredient' }
	{ slug: 'effect', title: 'Effect' }
	{ slug: 'occasion', title: 'Occasion' }
]

# Component def
export default

	components: {
		FilterableList
	}

	props: block: Object

	# Get all products and categories
	asyncData: ({ app: { $craft }}, block) ->

		# Get the initial page of results
		{ flavors, ...categories } = await $craft.execute
			query: getProducts
			variables: withCategories: true

		# Store responses
		Vue.set block, 'flavors', flavors
		Vue.set block, 'categories', categories

	computed:

		# Vuex accessors
		brands: -> @$store.state.navigation.brands

		# Make the list of all filter categories and their options
		filters: -> filterCategories.map ({ title, slug }) =>
			title: title
			slug: slug
			options: switch slug
				when 'brand' then @brands
				when 'productType' then @block.categories.productType.map (option) ->
					title: option.title
					slug: option.slug
					description: option.productTypeDescription
				else @block.categories[slug]

		# Map the flavors to an easily filtered array by adding an attributes
		# property with the elements that will be filtered upon
		flavors: -> @block.flavors.map (flavor) =>
			Object.assign {}, flavor, filterAttributes:
				brand: [ flavor.brand.slug ]
				productType: flavor.productType.map ({ slug }) -> slug
				availability: flavor.availability.map ({ slug }) -> slug
				activeIngredient: flavor.activeIngredient.map ({ slug }) -> slug
				effect: flavor.effect.map ({ slug }) -> slug
				occasion: flavor.occasion.map ({ slug }) -> slug

