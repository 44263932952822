



















export default

	# Not type hinted so we can test for undefined
	props:
		open: type: undefined
		active: Boolean

	data: -> internalOpenState: false

	computed:

		# Use open prop for state if passed, otherwise use internal
		useInternalState: -> @open == undefined
		interacting: -> switch
			when @active then true
			when @useInternalState then @internalOpenState
			else @open

