




















import AffixingHeader from '../affixing-header'
import Hamburger from './hamburger'
import MobileMenu from './mobile-menu'
export default

	components: {
		AffixingHeader
		Hamburger
		MobileMenu
	}

	data: -> open: false

	watch:

		# When the route changes, close menu if open
		$route: -> @open = false if @open

