












export default

	data: -> social: 
		facebook: 'https://www.facebook.com/kivaconfections'
		twitter: 'https://twitter.com/kivaconfections'
		instagram: 'https://instagram.com/madebykiva/'

