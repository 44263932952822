import { render, staticRenderFns } from "./mobile-header.vue?vue&type=template&id=42cd10dc&scoped=true&lang=pug&"
import script from "./mobile-header.vue?vue&type=script&lang=coffee&"
export * from "./mobile-header.vue?vue&type=script&lang=coffee&"
import style0 from "./mobile-header.vue?vue&type=style&index=0&id=42cd10dc&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42cd10dc",
  null
  
)

export default component.exports