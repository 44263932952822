










export default
	name: "CareerInquiry"
