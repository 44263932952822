



























export default

	data: -> heights: []

	# I'm not updating this on resize because I think it's pretty edge case
	# that someone would resize this while the menu is open. It's kind of
	# impossible ... and re-calculates on each open.
	mounted: -> @calculateHeights()

	computed:

		# VueX
		brands: -> @$store.state.navigation.brands

		# Calculate the height to render the menu out so the colums look balanced
		height: ->
			totalHeight = @heights.reduce ((sum, val) -> sum + val), 0
			runningTotal = 0
			for height, index in @heights
				runningTotal += height
				return runningTotal if runningTotal > totalHeight / 2

		# Styles added to menu
		styles: -> height: "#{@height}px"

	methods:

		# Calculate what the height should be to balance the columns when wrapping
		calculateHeights: ->
			@heights = Array.from(@$el.children).map (el) ->
				el.clientHeight + parseInt getComputedStyle(el).marginTop

