





















export default

	props: block: Object

	computed:

		classes: -> @$kebab @block.maxWidth

		# Make the shim padding style
		aspect: ->
			return unless @block.aspectRatio?.match /\d+:\d+/
			[width, height] = @block.aspectRatio.split(':')
			return width/height
		aspectPadding: -> "#{1 / @aspect * 100}%" if @aspect

