



















import BlockWrapper from './block-wrapper'

# Block components
import Accordion from '~/components/blocks/accordion'
import Anchor from '~/components/blocks/anchor'
import Article from '~/components/blocks/article'
import ArticleList from '~/components/blocks/article-list/article-list'
import CardCarousel from '~/components/blocks/card-carousel'
import CardGrid from '~/components/blocks/card-grid'
import CareersList from '~/components/blocks/careers-list/careers-list'
import EventsFinder from '~/components/blocks/events-finder/events-finder'
import MultipartContactForm from '~/components/blocks/multipart-contact/form-choice'
import Copy from '~/components/blocks/copy'
import Locator from '~/components/blocks/locator/locator'
import Marquee from '~/components/blocks/marquee'
import Media from '~/components/blocks/media'
import Newsletter from '~/components/blocks/newsletter'
import ProductFilter from '~/components/blocks/product-filter'
import ScrapbookEntry from '~/components/blocks/scrapbook-entry'
import ScrapbookMarquee from '~/components/blocks/scrapbook-marquee'
import Spacer from '~/components/blocks/spacer'
import Split from '~/components/blocks/split'
import FlavorTranslations from '~/components/blocks/flavor-translations'

# Mapping of Craft block matrix _typename to component
export mapping =
	blocks_accordion_BlockType: Accordion
	blocks_anchor_BlockType: Anchor
	blocks_article_BlockType: Article
	blocks_articleList_BlockType: ArticleList
	blocks_cardCarousel_BlockType: CardCarousel
	blocks_cardGrid_BlockType: CardGrid
	blocks_careersList_BlockType: CareersList
	blocks_contactForm_BlockType: MultipartContactForm
	blocks_copy_BlockType: Copy
	blocks_eventsFinder_BlockType: EventsFinder
	blocks_locator_BlockType: Locator
	blocks_marquee_BlockType: Marquee
	blocks_media_BlockType: Media
	blocks_newsletter_BlockType: Newsletter
	blocks_productFilter_BlockType: ProductFilter
	blocks_scrapbookEntry_BlockType: ScrapbookEntry
	blocks_scrapbookMarquee_BlockType: ScrapbookMarquee
	blocks_spacer_BlockType: Spacer
	blocks_split_BlockType: Split
	blocks_flavorTranslations_BlockType: FlavorTranslations

# Component def for this block
export default

	components: { ...mapping, BlockWrapper }

	props: blocks: Array

	computed:

		# Filter the blocks to those that have been defined.  To fix errors in dev
		# environments after content model is created but commits with the new
		# block component have not been pulled down.
		createdBlocks: ->
			typenames = Object.keys(mapping)
			(@blocks || []).filter (block) -> typenames.includes block.__typename

# Helper for loading async data from blocks
export loadBlockData = (context, blocks = [], extra) ->
	Promise.all blocks.map (block) ->
		if func = mapping[block.__typename]?.asyncData
		then func(context, block, extra)
		else Promise.resolve()

