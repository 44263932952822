
































































import formMixin from './form-mixin'

export default
	name: "PressInquiry"

	mixins: [ formMixin ]

	inject: ['blockIsDark']

	props:
		block: Object

	data: ->
		valid: false
		form:
			'form-name': 'Press Inquiry'
			name: ''
			email: ''
			message: ''
			attachment: null
		validation:
			name: [ @$validators.required]
			email: [ @$validators.required, @$validators.email ]
			message: [ @$validators.required ]
		submitting: false
		submitted: false

