








import pngWatermark from './sold-out.png'

export default
	data: ->
		pngPath: pngWatermark
