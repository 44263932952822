


















import FilterTitle from './filters-title'
export default

	props:
		title: String
		total: Number

	components: { FilterTitle }


