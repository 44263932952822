































export default

	props: career: Object

	computed:

		# Convenience accessor
		categories: -> @career.categories

	methods:

		# Make a link to the filter
		url: (category) ->
			"#{@$route.path}?#{category}=#{@$kebab(@categories[category])}"


