import { render, staticRenderFns } from "./selector.vue?vue&type=template&id=cbe75ce4&scoped=true&lang=pug&"
import script from "./selector.vue?vue&type=script&lang=coffee&"
export * from "./selector.vue?vue&type=script&lang=coffee&"
import style0 from "./selector.vue?vue&type=style&index=0&id=cbe75ce4&lang=stylus&scoped=true&"
import style1 from "./selector.vue?vue&type=style&index=1&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cbe75ce4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VSelect})
