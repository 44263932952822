


















export default

	props: fill: String

