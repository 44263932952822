



























import MenuLink from './menu-link'
import PrimaryButton from './primary-button'
import StandardMenu from './standard-menu'
import ProductsMenu from './products-menu'

export default
	components: {
		MenuLink
		PrimaryButton
		StandardMenu
		ProductsMenu
	}

	computed:

		# Determine if active
		navigation: ->
			for option in @$store.state.navigation.header
				{ ...option, active: @isActive option }

	methods:

		# Determine if an option is active
		isActive: (option) ->
			return false if @$route.path == '/'
			for url in @getUrls option
				return true if @$route.path.includes @getPath url

		# Get the list of paths for a nav option
		getUrls: (option) -> switch
			when option.type == 'navigationUrl' then [option.href]
			when option.type == 'navigationCategory'
				child.href for child in option.children
			when option.specialType == 'headerProducts'
				@$store.state.navigation.brands.reduce (paths, brand) ->
					paths.push brand.href
					for product in brand.products
						paths.push product.href
					return paths
				, []
			else []

		# Get the path for a url
		getPath: (url) ->
			url
			.replace /^https?:\/\/[^\/]+/, '' # Replace hostname
			.replace /(\?|\#).*/, '' # Remove query string and anchor

		# Add extra click behavior for some links
		onClick: (option) ->
			return if Modernizr?.touchevents
			path = switch
				when option.specialType == 'headerProducts' then '/products'
				when option.title == 'About' then '/about-us'
			@$router.push path if path

