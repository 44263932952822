












export default

	props: links: Array

