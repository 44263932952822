























































export default

	inject: noResultsList: default: false

	props:
		location: Object
		noIndexNums: Boolean

	computed:

		# VueX accessor
		allBrands: -> @$store.state.navigation?.brands || []

		# Should the address block be shown
		hasAddress: -> @location.adresss || @region

		# Make the city, state, zip string
		region: ->
			@$nonEmpty([
				@$nonEmpty([@location.city, @location.state]).join(', ')
				@location.zip
			]).join(' ')

		# Make the directions URL
		# https://developers.google.com/maps/documentation/urls/guide
		directions: ->
			return unless @location.lat and @location.lng
			'https://www.google.com/maps/dir/?api=1&' + new URLSearchParams(
				destination: "#{@location.lat},#{@location.lng}"
			).toString()

		# Make the brands array
		brands: -> @$nonEmpty (@location.brands || []).map (brandSlug) =>
			@allBrands.find (brand) -> brand.slug == brandSlug

