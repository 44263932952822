import getGlobals from '~/queries/globals.gql'

# Simple global vars
export state = -> loading: false

export mutations =

	# Store the loading state
	setLoading: (state, bool) -> state.loading = bool

export actions =

	# Delegate global queries
	nuxtServerInit: ({ commit }) ->

		# Fetch and destrucutre global data
		{ globals,
			navigation,
			brands,
			productTypes,
			promoBars,
		} = await @$craft.execute query: getGlobals

		# Store to Nuxt moduels
		commit 'globals/set', globals
		commit 'navigation/set', { navigation, brands, productTypes }
		commit 'promo-bars/set', promoBars
