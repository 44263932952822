














export default

	props: category: Object

	computed:

		title: -> switch @category.specialType
			when 'footerBrands' then 'Brands'
			when 'footerProducts' then 'Products'
			else @category.title

		links: -> switch @category.specialType
			when 'footerBrands' then @$store.state.navigation.brands
			when 'footerProducts' then [
					{ title: 'All', href: '/products' },
					...@$store.state.navigation.productTypes
				]
			else @category.children

