import { render, staticRenderFns } from "./product-complaint.vue?vue&type=template&id=9f9b007c&scoped=true&lang=pug&"
import script from "./product-complaint.vue?vue&type=script&lang=coffee&"
export * from "./product-complaint.vue?vue&type=script&lang=coffee&"
import style0 from "./product-complaint.vue?vue&type=style&index=0&id=9f9b007c&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f9b007c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VFileInput,VForm,VTextField,VTextarea,VTooltip})
