





















import { unpackCards } from '~/components/global/cards/craft-card'
export default
	name: 'CardCarousel'

	props: block: Object

	data: ->
		hideImgs: false
		dragging: false

	# Instantiate and cleanup Flickity
	mounted: -> @flickity = @initFlickity()
	destroyed: -> @flickity?.destroy()

	computed:

		# Unpack brands
		cards: -> unpackCards @block.cards

		# Add classes
		classes: ->
			'hide-imgs': @hideImgs

			# Class that work with watchCSS to disable when not enough slides
			'more-than-5': @cards.length > 5
			'more-than-4': @cards.length > 4
			'more-than-3': @cards.length > 3
			'more-than-2': @cards.length > 2

	methods:

		# Create flickity
		initFlickity: -> new @$flickity @$refs.carousel,
			wrapAround: 'wrapAround' in @block.config
			freeScroll: 'freeScroll' in @block.config
			pageDots: 'addDots' in @block.config
			groupCells: true
			watchCSS: true
			prevNextButtons: false

			# Trigger intesection-observer based loading of images
			on:
				ready: @onFlickityReady

				# Used to prevent clicks inside card from working until dragging done
				dragStart: => @dragging = true
				dragEnd: => @$defer => @dragging = false

		# Toggle the display none of internal visuals to trigger a new intersection
		# observer check, because the load detection was failing when Flickity
		# mounts.
		onFlickityReady: ->
			@hideImgs = true
			@$defer => @hideImgs = false

		# Prevent clicks on card links from working while dragging
		onClick: (e) -> e.preventDefault() if @dragging

