





























export default
	name: 'CopyBlock'

	props: block: Object

