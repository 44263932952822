















import SocialLinks from '~/components/global/social-links'
export default

	components: {
		SocialLinks
	}

	data: -> social: 
		facebook: 'https://www.facebook.com/kivaconfections'
		twitter: 'https://twitter.com/kivaconfections'
		instagram: 'https://instagram.com/madebykiva/'

