














































import Vue from 'vue'
import Marquee from '~/components/pages/article/marquee'
import Categories from './categories'
import ArticleCard from './article-card'
import getArticles from '~/queries/blocks/articles.gql'
PER_PAGE = 12
export default

	components: {
		Marquee
		Categories
		ArticleCard
	}

	props: block: Object

	data: ->
		activeCategory: null # The category slug
		loading: false
		page: 0
		perPage: PER_PAGE
		results: @block.articles
		hasMore: @block.hasMore

	# Get category and article list
	asyncData: ({ app: { $craft }}, block) ->

		# Get the initial page of results
		{ entries, next, categories } = await $craft.execute
			query: getArticles
			variables:
				type: block.articleType
				id: "not #{ignoreId}" if ignoreId = block.featured?[0]?.id
				offset: 0
				limit: PER_PAGE
				nextOffset: PER_PAGE
				includeCategories: block.articleType == 'blog'

		# Store responses
		Vue.set block, 'articles', entries
		Vue.set block, 'hasMore', !!next
		Vue.set block, 'categories', categories if categories

	computed:

		# Get the active category id
		categoryId: ->
			return unless @block.categories and @activeCategory
			@block.categories.find(({ slug }) => slug == @activeCategory).id

		# Build the query variables
		query: ->
			type: @block.articleType
			id: "not #{ignoreId}" if ignoreId = @block.featured?[0]?.id
			category: @categoryId
			offset: @page * @perPage
			limit: @perPage
			nextOffset: (@page + 1) * @perPage
			includeCategories: false

	watch:

		# When the category changes, reset the page
		activeCategory: -> @page = 0

		# When the query obj changes, fetch new data
		query: -> @fetch()

	methods:

		# Fetch data from the server
		fetch: ->
			page = @page # Remember the page at fetch time, in case spammed

			# Run query
			@loading = true
			{ entries, @hasMore } = await @$craft.getPaginatedEntries
				query: getArticles
				variables: @query
			@loading = false

			# Store the results
			@results = if page == 0
			then entries # Replace if first page was fetched
			else [ ...@results, ...entries ] # Concat, if load more


