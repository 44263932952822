import { render, staticRenderFns } from "./affixing-header.vue?vue&type=template&id=7e9ff8e7&scoped=true&lang=pug&"
import script from "./affixing-header.vue?vue&type=script&lang=coffee&"
export * from "./affixing-header.vue?vue&type=script&lang=coffee&"
import style0 from "./affixing-header.vue?vue&type=style&index=0&id=7e9ff8e7&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e9ff8e7",
  null
  
)

export default component.exports