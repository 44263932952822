# Shared styling for cards
export default

	computed:

		# Srcset sizes, these should match the config in card-grid. The 4/5 column
		# breaks are different because the expectation is that the grid is wrapped
		# in a max-w-wide or -full and thus has more width to work with. These rules
		# coinside with sizing from the card-carousel and card-grid blocks.
		sizes: -> switch @columns
			when 5 then '''
				(max-width: 500px) 100vw, 
				(max-width: 767px) 50vw, 
				(max-width: 1190px) 25vw, 
				20vw
			'''
			when 4 then '''
				(max-width: 500px) 100vw, 
				(max-width: 767px) 50vw, 
				(max-width: 1190px) 33vw, 
				25vw
			'''
			when 3 then '''
				(max-width: 767px) 100vw, 
				(max-width: 1023px) 50vw, 
				33vw
			'''
			when 2 then '''
				(max-width: 767px) 100vw, 
				50vw
			'''
