export state = ->
	bars: []
	height: 0

export mutations =

	# Set the bars
	set: (state, bars) -> state.bars = bars

	# Set the height
	setHeight: (state, value) -> state.height = value || 0

export getters =

	# Make a CSS rule for the margin offset from the height
	offsetStyles: (state) -> marginTop: "#{state.height}px"
