






























































# Child components
import Geocoder from './geocoder'
import GoogleMap from './google-map'
import Results from './results'
import Tabs from './tabs'

# Libraries
import getDistance from 'geolib/es/getDistance'
import convertDistance from 'geolib/es/convertDistance'
import round from 'lodash/round'
import sortBy from 'lodash/sortBy'
import inViewportMixin from 'vue-in-viewport-mixin'

# Helper for making coords for geoliub
longCoords = (coords) -> { latitude: coords.lat, longitude: coords.lng }

# Settings
import { BUY_NOW_URL } from '~/components/global/btn/buy-now'
NEARBY_RESULTS = 6 # Should be a multiple of 6
OVERVIEW_RESULTS = 100

export default

	components: {
		Geocoder
		GoogleMap
		Results
		Tabs
	}

	mixins: [ inViewportMixin ]

	provide: -> { @noResultsList }

	props:
		block: Object
		noResultsList: Boolean
		forcePinIcon: Boolean
		forceOverviewMode: Boolean
		showAll: Boolean

	data: ->
		origin: null # The looked up location Place
		loading: false
		selectedTab: @block.defaultTab
		results: []
		resultsOrigin: null
		page: 1 # The selected page
		renderedPage: 1 # The page that we have data for
		totalResults: 0 # The total results available for the origin

	# Create debounced queryLocator
	created: -> @queryLocatorDebounced = @$debounce 300, (...args) =>
		@runQuery => @queryLocator.apply this, args

	mounted: ->

		# Load JS library onto window
		await @loadGoogleMaps()

		# Create the autocomplete service instance
		@$refs.geocoder.createService()

		# Set current tab if in query, waiting till mounted to prevent SSG issues
		@selectedTab = tab if tab = @$route.query.locator

	computed:

		# Classes add to root
		classes: -> [
			"tab-#{@selectedTab}" if @selectedTab
		]

		# What tab are we looking
		isDispensary: -> !@selectedTab or @selectedTab == 'dispensary'
		isDelivery: -> @selectedTab == 'delivery'

		# Make a simplified object for the intial state of the map
		initialState: ->
			lat: parseFloat @block.initialLatitude
			lng: parseFloat @block.initialLongitude
			zoom: parseInt @block.initialZoom

		# Order the results and add index numbers
		orderedResults: ->

			# Limit the results count, which may have excess as a result of "direct"
			# being added in
			results = @results.slice 0, @renderedPage * NEARBY_RESULTS

			# Calculate distance
			results = results.map (result) =>
				unless @resultsOrigin and result.lat and result.lng
				then return { ...result, distance: '' }
				meters = getDistance longCoords(result), longCoords(@resultsOrigin), 100
				miles = convertDistance meters, 'mi'
				Object.assign {}, result,
					distanceNum: miles
					distance: "#{round(miles, 1)} miles"

			# Order by distance
			results = sortBy results, 'distanceNum' if @resultsOrigin

			# Add index strings and return
			results.map (result, index) => Object.assign {}, result,
				index: @$padNum index + 1

		# Determine whether we're showing an overview mode (small pins, no text
		# results) or results mode. Basically, be in results mode if the user has
		# searched for nearby results via specifying an origin. Otherwise, base
		# it on the number of results found.
		overviewMode: ->
			return true if @forceOverviewMode
			return false if @resultsOrigin
			return true if @results.length > NEARBY_RESULTS

		# Make a list of all slugs
		brandSlugs: -> @$store.state.navigation.brands.map ({ slug }) -> slug

		# Is there a direct result
		hasDirectResult: -> @results[0]?.id == 'direct'

		# Get the total pages
		totalPages: ->
			adjustedTotal = @totalResults + if @hasDirectResult then 1 else 0
			unless adjustedTotal then 0
			else Math.ceil adjustedTotal / NEARBY_RESULTS

		# More delivery locations should be fetched if there are more and the
		# bottom of the component is in the viewport
		shouldAutoFetchMore: ->
			@isDelivery and # Doesn't apply to dispensaries
			@totalPages > 0 and
			@page < @totalPages and
			not @inViewport.below and
			not @loading

	watch:

		# Update the origin used in computed props when the results change
		results: -> @resultsOrigin = @origin

		# Update the active tab
		'$route.query.locator': (choice) -> @selectedTab = choice

		# Requery when the tab changes
		selectedTab: ->
			@results = []
			@resetPagination()
			@getNearbyResults()

		# Autofetch a new page
		shouldAutoFetchMore: (bool) ->
			return unless bool
			@page += 1
			@getNearbyResults()

	methods:

		# Load the Google Maps API
		loadGoogleMaps: -> new Promise (resolve) =>
			return resolve() if window.google?.maps
			window.googleMapsLoaded = resolve
			src = "https://maps.googleapis.com/maps/api/js?\
				libraries=places&\
				key=#{process.env.GOOGLE_MAPS_API_KEY}&\
				callback=googleMapsLoaded"
			await @$loadScript src, defer: true

		# Update the origin with the looked up place and fetch results
		onPlace: (@origin) ->
			@resetPagination()
			if @origin then @getNearbyResults()
			else if googleMap = @$refs.map?.map
			then @onBoundsChanged googleMap.getBounds()

		# Get nearby results
		getNearbyResults: ->
			return unless @origin
			if @isDispensary
			then @getNearbyDispensaries()
			else @getNearbyDeliveryServices()

		# Get nearby dispensaries
		getNearbyDispensaries: ->
			await @runQuery => @queryLocator '/dispensaries/nearby',
				lat: @origin.lat
				lng: @origin.lng
				page: @page
				perPage: NEARBY_RESULTS
				showAll: @showAll
			@$refs.map?.fitBounds()

		# Get nearby delivery options, clearing results if the new zip isn't valid.
		# Also, unshift the direct result onto the front of the results if the
		# zip was valid. The direct query and the delivery services queries are
		# executed in tandem.
		getNearbyDeliveryServices: ->
			return @results = [] unless @origin.zip
			@runQuery =>
				[direct, results] = await Promise.all [
					if @page == 1 then @queryDirect @origin.zip else Promise.resolve()
					@queryLocator '/delivery-services',
						zip: @origin.zip
						page: @page
						perPage: NEARBY_RESULTS
				]
				if direct then [direct, ...results] else results

		# Query the API for a list of locations given the current bounds of the map.
		# We only need a reduced set of results if an origin has been specified
		# since we're not going to display all.
		onBoundsChanged: (bounds) ->
			ne = bounds.getNorthEast()
			sw = bounds.getSouthWest()
			@loading = true
			@queryLocatorDebounced '/dispensaries/box',
				neLat: ne.lat()
				neLng: ne.lng()
				swLat: sw.lat()
				swLng: sw.lng()
				page: 1
				perPage: if @origin then NEARBY_RESULTS else OVERVIEW_RESULTS
				showAll: @showAll

		# Shared logic for setting loading and results
		runQuery: (query) ->
			@loading = true

			# Append data if not page 1
			newResults = (await query()) || []
			unless @page > 1 then @results = newResults
			else @results = [...@results, ...newResults]

			# Wait for results to render before clearing loading state. This was added
			# to prevent shouldAutoFetchMore from firing before the updated height of
			# the results could be taken into account.  Smaller delays (like 50),
			# were too early. This could be because of the affect of transitions.
			clearInterval @loadingWaitId
			@loadingWaitId = @$wait 100, => @loading = false

		# Get results from the API
		queryLocator: (endpoint, params) ->

			# Cancel existing queries
			@locatorCancel?.cancel()
			@locatorCancel = @$axios.CancelToken.source()

			# Query API
			try response = await @$axios.get endpoint,
				params: params
				cancelToken: @locatorCancel.token
			catch thrown # Ignore errors, 400 is returned for invalid zip

			# Cleanup and return results
			@locatorCancel = null
			@totalResults = response?.data?.total
			@renderedPage = response?.data?.currentPage
			return response?.data?.items

		# Query the grassDoor API
		queryDirect: (zip) ->
			return unless await @$grassdoor.isZipSupported zip
			id: 'direct'
			name: 'Kiva Direct'
			url: BUY_NOW_URL
			brands: @brandSlugs

		# Check if in bounds that is safe for cylindrical projections
		# https://stackoverflow.com/a/10940116/59160
		inBounds: (point, bounds) ->
			eastBound = point.lng < bounds.neLng
			westBound = point.lng > bounds.swLng

			inLong = if bounds.neLng < bounds.swLng
			then inLong = eastBound || westBound
			else inLong = eastBound && westBound

			inLat = point.lat > bounds.swLat && point.lat < bounds.neLat
			return inLat && inLong

		# Reset the page
		resetPagination: -> @renderedPage = @page = 1

