###
State related to whether the user's zip supports buy now
###
export LS_KEY = 'buy:zip'
export DETECTED_KEY = 'buy:zip:detected'

# MaxMind temporarily disabled as part of:
# https://app.asana.com/0/1194663623278345/1201341109880498/f
# As a result, set supported to true to always display

export state = ->
	zip: null
	supported: true

export mutations =

	setZip: (state, { zip, detected = null }) ->
		state.zip = zip
		@$localStorage.setItem LS_KEY, zip

		# If a detected value was explicitly passed, write that to localStorage.
		# One wouldn't be set when we are hyrating vuex from localStorage, like from
		# a Nuxt plugin.
		if detected != null
		then @$localStorage.setItem DETECTED_KEY, detected

	# setSupported: (state, supported) -> state.supported = supported

# export actions =

	# Check with Grassdoor whether the zip is supported
	# checkSupported: ({ commit}, zip) ->
	# 	commit 'setSupported', await @$grassdoor.isZipSupported zip
