



























import Selector from '~/components/global/selector'
import cardMixin from './card-mixin'

export default ListingCard =
	name: 'LinkListCard'

	mixins: [ cardMixin ]

	components: { Selector }

	props:

		# Layout
		aspect: Number # Applied to the image
		index: Number
		columns:
			type: Number
			default: 2
		scaleIn:
			type: Boolean
			default: true

		# Card content
		menuTitle: String
		image: Object | Array
		links: Array

	computed:

		# VueX
		viewport: -> @$store.state.layout.viewport

		# Lookup the category group
		options: -> @links.map ({ label, href }) ->
			text: label
			value: href

		# Determine how to align the selector
		selectorAlign: -> switch
			when @columns == 2 and @viewport.width >= 768
				if @index % 2 then 'right' else 'left'
			else 'center'

	methods:

		# Handle choice of a category
		onChoice: (href) -> @$router.push href

