
























# Deps
import Vue from 'vue'
import FilterableList from '~/components/global/filterable-list/filterable-list'
import Career from './career'

# The filter categories to expect and the order to show them
filterCategories = [
	{ slug: 'location', title: 'Location' }
	{ slug: 'team', title: 'Team' }
	{ slug: 'department', title: 'Department' }
	{ slug: 'commitment', title: 'Commitment' }
]

export default

	components: {
		FilterableList
		Career
	}

	props: block: Object

	# Get the list of openings
	asyncData: ({ app: { $axios }}, block) ->
		{ data: careers } = await $axios.get block.feedUrl
		Vue.set block, 'careers', careers

	# Update the careers on mount in case there are new ones since last SSG
	mounted: -> @$defer @updateCareers

	computed:

		# Make the list of all filter categories and their options
		filters: -> filterCategories.map ({ title, slug }) =>
			title: title
			slug: slug

			# Loop through all careers and build of list of unique option values for
			# this category
			options: @block.careers.reduce (options, career) =>
				title = career.categories[slug]
				optionSlug = @$kebab title
				unless options.find (option) -> option.slug == optionSlug
				then options.push { title, slug: optionSlug }
				return options
			, []

		# Map the flavors to an easily filtered array by adding an attributes
		# property with the elements that will be filtered upon
		careers: -> @block.careers.map (career) =>
			Object.assign {}, career, filterAttributes:
				location: [ @$kebab career.categories.location ]
				team: [ @$kebab career.categories.team ]
				department: [ @$kebab career.categories.department ]
				commitment: [ @$kebab career.categories.commitment ]

	methods:

		# Re-update the careers list
		updateCareers: ->
			{ data: careers } = await @$axios.get @block.feedUrl
			Vue.set @block, 'careers', careers

