


























import Vue from 'vue'
import { unpackCards } from '~/components/global/cards/craft-card'
import getProductCategoryGroups from '~/queries/blocks/product-category-groups.gql'
export default
	name: 'CardGrid'

	props: block: Object

	asyncData: ({ app: { $craft }}, block) ->
		return unless block.cards.find ({ type }) -> type == 'categoryGroupCard'

		# Get all the product category groups
		categories = await $craft.execute
			query: getProductCategoryGroups

		# Merge the list of categories into relevant cards
		for card in block.cards
			if card.type == 'categoryGroupCard'
				Vue.set card, 'categories', categories[card.productCategoryGroup] || []

	computed:

		# Unpack brands
		cards: -> unpackCards @block.cards

